import Stats from '../views/stats';
import Menu from '../views/menu';
import Verkooppunten from '../views/verkooppunten';
import Bezoekers from '../views/bezoekers';
import Logout from '../views/logout';
import Instellingen from '../views/instellingen';
import Lockers from '../views/lockers';
import Notificaties from '../views/notificaties';
import Promoties from '../views/promoties';
import Facturen from '../views/facturen';

var ThemeRoutes = [
	{
		path: "/event-stats",
		name: "Statistieken",
		mini: "B",
		icon: "fa-regular fa-wave-pulse",
		component: Stats
	},
	{
		path: "/event-barren",
		name: "Afhaalpunten",
		mini: "B",
		icon: "fa-regular fa-store",
		component: Verkooppunten
	},
	{
		path: "/event-menu",
		name: "Assortiment",
		mini: "B",
		icon: "fa-regular fa-burger",
		component: Menu
	},
	{
		path: "/event-kluizen",
		name: "Lockers",
		mini: "B",
		icon: "fa-regular fa-unlock-alt",
		component: Lockers
	},
	{ 	path: '/event-promoties',
		name: 'Muntjes', 
		icon: 'fa-regular fa-circle-o', 
		component: Promoties 
	},
	{ 	path: '/event-notifications',
		name: 'Berichten', 
		icon: 'fa-regular fa-message-minus', 
		component: Notificaties 
	},
	{ 	path: '/event-bezoekers',
		name: 'Bezoekers', 
		icon: 'fa-regular fa-user', 
		component: Bezoekers 
	},
	{	path: '/event-facturen',
		name: 'Facturen', 
		icon: 'fa-regular fa-envelope-open-dollar', 
		component: Facturen 
	},
	{ 	path: '/event-instellingen',
		name: 'Instellingen', 
		icon: 'fa-regular fa-gear', 
		component: Instellingen 
	},
	{ 	path: '/Uitloggen',
		name: 'Uitloggen', 
		icon: 'fa-regular fa-door-open', 
		component: Logout 
	},
	{ path: '/', pathTo: '/event-stats', name: 'Statistieken', redirect: true }
];
export default ThemeRoutes;
