import React from 'react';
import {InputGroup, Input, CustomInput, Form, Row, Col, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import { auth } from '../components/firebase/firebase';

import img2 from '../assets/images/loginbg.jpg';
import imglogo from '../assets/images/pp_logo.svg';

const validators = {

  email: {
    rules: [
      {
        test: /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i,
        message: 'Geef een valide e-mail op',
      },
    ],
    errors: [],
    valid: false,
    state: '',
  },
  password: {
    rules: [
      {
        test: (value) => {
          return value.length >= 6;
        },
        message: 'Wachtwoord moet minimaal 6 characters hebben',
      },
      {
        test: /^[a-z0-9A-Z_]+$/,
        message: 'Geef een valide wachtwoord op',
      },
    ],
    errors: [],
    valid: false,
    state: ''
  },
  username: {
    rules: [
      {
        test: /^[a-zA-Z_]+$/i,
        message: 'Nummers zijn niet toegestaan',
      },
    ],
    errors: [],
    valid: false,
    state: '',
  },
};


const sidebarBackground = {
	backgroundImage: "url(" + img2 + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center center"
};

class Login extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			error: null,
			modal: false,
		};
		this.handleClick = this.handleClick.bind(this);
		this.validators = validators;
		this.onInputChange = this.onInputChange.bind(this);
		this.doLogin = this.doLogin.bind(this);
		this.showErrors = this.showErrors.bind(this);
		this.formValidators = this.formValidators.bind(this);
	}
	
	toggle = () => {this.setState({'modal': !this.state.modal});}
	
	onInputChange(event) {
		this.setState({
			[event.target.name]: event.target.value
		});
		this.formValidators([event.target.name], event.target.value);
	}
	
	formValidators(fieldName, value) {
		this.validators[fieldName].errors = [];
		this.validators[fieldName].state = value;
		this.validators[fieldName].valid = true;
		this.validators[fieldName].rules.forEach((rule) => {
			if (rule.test instanceof RegExp) {
				if (!rule.test.test(value)) {
					this.validators[fieldName].errors.push(rule.message);
					this.validators[fieldName].valid = false;
				}
			} else if (typeof rule.test === 'function') {
				if (!rule.test(value)) {
					this.validators[fieldName].errors.push(rule.message);
					this.validators[fieldName].valid = false;
				}
			}
		});
	}
	
	validForm() {
		let status = true;
		Object.keys(this.validators).forEach((field) => {
			if (field === 'email' || field === 'password') {
				if (!this.validators[field].valid) {
					status = false;
				}
			}
		});
		return status;
	}
	
	showErrors(fieldName) {
		const validator = this.validators[fieldName];
		const result = '';
		if (validator && !validator.valid) {
			const errors = validator.errors.map((info, index) => {
				return <span className="error" key={index}>* {info}<br /></span>
			});
			return (
				<div className="error mb-2">
					{errors}
				</div>
			);
		}
		return result;
	}
	
	doLogin(event) {
		const {
			email,
			password
		} = this.state;
		auth.signInWithEmailAndPassword(email, password)
			.then(() => {
				this.setState(() => ({
					email: email,
					password: password,
				}));
				window.location.href = "/"; ;
			})
			.catch(error => {
				this.toggle();
			});
		event.preventDefault();
	}
	
	handleClick() {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}
	
	render() {
		return <div className="auth-wrapper  align-items-center d-flex" style={sidebarBackground}>
			{/*--------------------------------------------------------------------------------*/}
			{/*Login2 Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="container">
				<div >
					<Row className="no-gutters justify-content-center">
						<Col md="6" lg="4" className="bg-white" style={{borderRadius: 7, boxShadow: '0px 0px 18px 1px rgba(0,0,0,0.22)'}}>
							<div className="p-4">
								<div style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
									<center><img src={imglogo} style={{width: 200}} alt='Partypay logo'/></center>
									<Form className="mt-3" id="loginform" action="/dashbaord">
										<Label for="email" className="font-medium">Email</Label>
										<InputGroup className="mb-2" size="lg">
											<Input type="email" id="email" name="email" value={this.state.email} onChange={this.onInputChange} placeholder="Email" style={{border: '1px solid #bfbfbf'}}/>
										</InputGroup>
										{this.showErrors('email')}
										<Label for="password" className="mt-3 font-medium">Wachtwoord</Label>
										<InputGroup className="mb-3" size="lg">
											<Input type="password" id="password" name="password" value={this.state.password} onChange={this.onInputChange} placeholder="********"  style={{border: '1px solid #bfbfbf'}}/>
										</InputGroup>
										{this.showErrors('password')}
										<div className="d-flex no-block align-items-center mb-4 mt-4">
											<CustomInput type="checkbox" id="exampleCustomCheckbox" label="Onthoud deze gegevens" />
										</div>
										<Row className="mb-3">
											<Col xs="12">
												<Button style={{backgroundColor: '#d12a5e', borderColor: '#d12a5e', marginTop: 20}} onClick={this.doLogin} size="lg" type="submit" block>Log In</Button>
											</Col>
										</Row>
									</Form>
								</div>
							</div>

						</Col>
					</Row>
				</div>
			</div>
			<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered >
				<ModalHeader toggle={this.toggle}>Foutmelding</ModalHeader>
				<ModalBody>
					<p style={{marginTop: 10}}>
					Gebruikersnaam en wachtwoord komen niet overeen.
					</p>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.toggle}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
		</div>;
	}
}

export default Login;
