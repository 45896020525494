import React from 'react';
import {  Router, Route, Switch, Redirect } from "react-router-dom";
import { auth } from '../components/firebase/firebase';
import { createMemoryHistory } from 'history';
import Blanklayout from '../layouts/blanklayout';
import indexRoutes from '../routes/';
import { observer, inject } from 'mobx-react';

const history = createMemoryHistory();
const LoginRoute = ({ component: Component, ...rest }) => (<Route {...rest} render={props => { return <Redirect to={{ pathname: '/authentication/login', state: { from: props.location } }} />}} />)
const AuthenticatedRoute = ({ component: Component, ...rest }) => ( <Route {...rest} render={props => {return <Component {...props} />}} />)

@inject('observableStore')
@observer

class Loginroute extends React.Component {
	
	state = {loginState: 0}
	
	componentDidMount() {
		auth.onAuthStateChanged((user) => {
			if (user) {
				auth.currentUser.getIdTokenResult().then((idTokenResult) => {
				if(idTokenResult.claims.admin) {
					this.props.observableStore.setAdmin();
					this.waitForEvent();
				}
				else if(idTokenResult.claims.organisatie){
					this.props.observableStore.setLoginInfo(user.uid, user.email);
					this.waitForEvent();
				}
				else{
					alert("je hebt niet de juiste machtigingen voor dit dashboard");
					this.setState({loginState: 2});
				}
				});
			}
			else{
				this.setState({loginState: 2});
			}
		})	
	}
	
	waitForEvent = () => {
		if(typeof this.props.observableStore.currEvent.ID !== "undefined" && this.props.observableStore.currEvent.ID !== ""){
			this.setState({loginState: 1});
		}
		else if(this.props.observableStore.newUser === true){
			this.setState({loginState: 1});
		}
		else if (this.props.observableStore.isAdmin === true){
			this.setState({loginState: 1});
		}
		else{
			setTimeout(this.waitForEvent, 250);
		}
	}
	
    render() {

        return (
		<Router basename="/" history={history}>
			<Switch>
				<Route path="/authentication/login" component={Blanklayout} />;
				{indexRoutes.map((prop, key) => {													
				   if(this.state.loginState === 1)
				   {	
					   return <AuthenticatedRoute path={prop.path} key={key} component={prop.component} />;  
				   }
				   else if(this.state.loginState === 2) {
					   return <LoginRoute path={prop.path} key={key} component={prop.component} />; 
				   }
				   return 0;
				})
			  }
			</Switch>
		</Router>
        )    
    }
}

export default Loginroute;