import React from "react";
import {Row, Col, Table, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label} from 'reactstrap';
import { storage, functionsEU } from '../components/firebase/firebase';
import { observer, inject } from 'mobx-react';
import Datetime from 'react-datetime';
import Warning from '../assets/images/pp_warning.svg';

@inject('observableStore')
@observer
class Facturen extends React.Component {
	render() {
		return (
			<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
				<Row>
					<Col lg={12}>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Facturen</h2>
					</Col>
				</Row>
				<Row className="menuTopCats">
					<Col lg={2} className="graph-display" style={{background: 'none', marginTop: 12, paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 240}}>
						<div className="settingsnav" style={{backgroundColor: this.state.factuurCat === 'Dit jaar' ? '#f6f6f6' : '', borderLeftWidth: this.state.factuurCat === 'Dit jaar' ? 6 : 0}} onClick={() => this.setState({factuurCat: 'Dit jaar'})}>
							<span>Dit jaar</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.factuurCat === 'Vorig jaar' ? '#f6f6f6' : '', borderLeftWidth: this.state.factuurCat === 'Vorig Jaar' ? 6 : 0}} onClick={() => this.setState({factuurCat: 'Vorig jaar'})}>
							<span>Vorig jaar</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.factuurCat === 'Alles' ? '#f6f6f6' : '', borderLeftWidth: this.state.factuurCat === 'Alles' ? 6 : 0}} onClick={() => this.setState({factuurCat: 'Vorig jaar'})}>
							<span>Alles</span>
						</div>
					</Col>
					<Col className="graph-display graph-menu" style={{marginLeft: 20, marginTop: 12, padding: 40, paddingTop: 30}}>	
						<div>
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 20, fontWeight: 900, color: '#212529',}}>{this.state.factuurCat}</h2>
								<div style={{display: 'flex'}}>
									{this.props.observableStore.isAdmin ?
									<div className="addBtnLst" style={{width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.newInvoice()}>
										Toevoegen										
									</div>
									:null}
								</div>
							</div>
							<div>
								{this.props.observableStore.currEvent.facturen && this.props.observableStore.currEvent.facturen.length > 0 ?
									<div style={{minHeight: 450}}>
										<Table className="v-middle" responsive>
											<thead>
												<tr>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Factuurnummer</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Datum</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Status</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Bedrag</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}></td>
												</tr>
											</thead>
											<tbody>
												{this.props.observableStore.currEvent.facturen.slice().sort((a, b) => b.date - a.date).map((factuur,index) => (										
													<tr style={{background:  index%2 !== 0 ? null : 'rgba(0,0,0,0.03)'}}>
														<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}></h6>{factuur.num}</td>
														<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}></h6>{this.formatDate(factuur.date)}</td>
														<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}></h6>{factuur.state}</td>
														<td className="stats-td stats-td-omzet"><h6 style={{background: '#43A047', fontWeight: 500, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ {parseFloat(factuur.amount).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
														<td style={{fontSize: 18, paddingRight: 30, textAlign: 'right'}}>
															<i class="fa fa-download hightlightIcon2" onClick={() => {window.open(factuur.url, '_blank')}} style={{cursor:'pointer'}}></i>
														</td>
													</tr>	
												))}
											</tbody>
										</Table>
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen facturen toegevoegd</span>
									</div>
								}
							</div>
						</div>
					</Col>
				</Row>
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered >
					<ModalHeader toggle={this.toggle}>Factuur toevoegen</ModalHeader>
					<ModalBody>
						{this.state.modalState === 0 ?
						<div>
							<Row>
								<Col sm={6}>
									<FormGroup>
										<Label htmlFor="example-email">Factuurnummer</Label>
										<Input style={{height: 40}} type="text" id="example-email" name="price" value={this.state.newInvoiceNum} onChange={e => this.setState({newInvoiceNum: e.target.value})}/>
									</FormGroup>
								</Col>
								<Col sm={6}>
									<FormGroup>
										<Label htmlFor="example-email">Factuur bedrag</Label>
										<Input style={{height: 40}} type="text" id="example-email" name="price" value={this.state.newInvoiceAmount} onChange={e => this.setState({newInvoiceAmount: e.target.value.replace(",", ".")})}/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col sm={6}>
									<FormGroup>
										<Label htmlFor="staticEmail" className="col-form-label">Factuur datum</Label>
										<div className="lockerDate">
											<i class="fa fa-calendar-day" style={{position: 'absolute', right: 15, top: 13, color: '#525f7f'}}></i>
											<Datetime timeFormat={false} value={this.state.newInvoiceDate} onChange={e => this.setState({newInvoiceDate: e})} closeOnSelect={true} dateFormat="dddd D MMMM YYYY" locale="nl"/>
										</div>
									</FormGroup>
								</Col>
								<Col sm={6}>
									<FormGroup>
										<label htmlFor="staticEmail" className="col-form-label">Status</label>
										<Input type="select" className='minimal' style={{width: '100%', height: 40, paddingBottom: 10, paddingTop: 10, paddingRight: 10, borderColor: 'rgb(217, 217, 217)', borderRadius: 5}} onChange={e => this.setState({newInvoiceState: e.target.value})}>
											<option value={'open'} selected={this.state.newInvoiceState === 'betaald'}>betaald</option>
											<option value={'open'} selected={this.state.newInvoiceState === 'open'}>open</option>
										</Input>									
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col sm={6}>
									{this.state.newInvoiceUrl === '' ?
									<FormGroup className="mb-1">
										<Label htmlFor="example-email" style={{marginBottom: 10}}>Upload een PDF</Label>		
										<div className="custom-file">
											<Input type="file" className="form-control" id="customFile1" onChange={e => this.setState({newInvoiceUrl: e.target.files[0]})} />
											<label className="custom-file-label" htmlFor="customFile1">Kies een bestand</label>
										</div>
									</FormGroup>
									:
									<div style={{display: 'flex'}}>
										<i class="fa fa-check" style={{marginTop: 10}}></i>
										<label style={{marginLeft: 15}} htmlFor="inputPassword" className="col-form-label">Bestand is geupload</label>
									</div>
									}
								</Col>
							</Row>
							<Row onClick={() => this.setState({newInvoicePay: !this.state.newInvoicePay})} style={{cursor: 'pointer', marginTop: 25, marginLeft: 1}}>
								{this.state.newInvoicePay ?
								<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
									<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
								</div>
								:
								<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
								}
								<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Factuur inschieten op boeksaldo pay.nl</p>
							</Row>
						</div>
						:null}
						{this.state.modalState === 1 ?
						<div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40}}>
							<div class="loading-spinner" style={{width: 35, height: 35, borderWidth: 4}}></div>
							<p style={{marginTop: 20}}>Factuur inschieten op boeksaldo</p>
						</div>
						:null}
						{this.state.modalState === 2 ?
							<p style={{marginTop: 10}}>Factuur is succesvol ingeschoten op het boeksaldo van de merchant</p>
						:null}	
						{this.state.modalState === 3 ?
							<p style={{marginTop: 10}}>Er is iets mis gegaan, fout: {this.state.modalError}</p>
						:null}	
					</ModalBody>
					<ModalFooter>
						{this.state.modalState === 0 ?
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.addInvoice}>
						Toevoegen
						</Button>
						:null}
						<Button className="cancel_btn" onClick={this.toggle}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
	
state = {
		factuurCat: 'Dit jaar',
		modal: false,
		modalState: 0,
		modalError: '',

		newInvoiceNum: '',
		newInvoiceDate: new Date(),
		newInvoiceState: 'betaald',
		newInvoiceAmount: '',
		newInvoiceUrl: '',
		newInvoicePay: false,

	}	

	toggle = () => {this.setState({modal: !this.state.modal, modalState: 0, modalError: ''})}

	newInvoice(){
		this.setState({newInvoiceNum: '', newInvoiceDate: new Date(), newInvoiceState: 'betaald', newInvoiceAmount: '', newInvoiceUrl: '', newInvoicePay: false});
		this.toggle();	
	}

	addInvoice = () => {
		var file = this.state.newInvoiceUrl;	
		if(file !== undefined){
			this.setState({uploadState: true});		
			var ImageRef = storage.ref().child('admins/' + this.props.observableStore.uid + '/events/' + this.props.observableStore.currEvent.ID + '/facturen/' + this.state.newInvoiceNum);
			ImageRef.put(file).then(() =>{
				ImageRef.getDownloadURL().then((url) => {
					var invoiceArr = this.props.observableStore.currEvent.facturen ? [...this.props.observableStore.currEvent.facturen] : [];
					invoiceArr.unshift({
						num: this.state.newInvoiceNum, 
						date: Math.round(new Date(this.state.newInvoiceDate).getTime() / 1000),
						state: this.state.newInvoiceState, 
						amount: this.state.newInvoiceAmount, 
						url: url
					});
					if(this.state.newInvoicePay){this.addInvoicePay(this.state.newInvoiceNum, this.state.newInvoiceAmount, url, Math.round(new Date(this.state.newInvoiceDate).getTime() / 1000), invoiceArr)}	
					else{
						this.props.observableStore.currEvent.facturen = invoiceArr;
						this.props.observableStore.saveEventInfo();	
						this.toggle();
					}
				})
			})
		} 
	}

	addInvoicePay = (invoiceId, amount, invoiceUrl, date, invoiceArr) => {
		this.setState({modalState: 1});
		var addInvoice = functionsEU.httpsCallable('addInvoice');
		var invoiceData = {
			merchantId: this.props.observableStore.currEvent.pay.merchantId,
			invoiceId: invoiceId,
			amount: Math.round(parseFloat(amount) * 100),
			ename: this.props.observableStore.currEvent.naam + ' - ' + this.formatDate(date),
			invoiceUrl: invoiceUrl
		};
		console.log(invoiceData);
		addInvoice(invoiceData).then(result => {
			if(result.data.request.result === '1'){
				this.setState({modalState: 2})
				this.props.observableStore.currEvent.facturen = invoiceArr;
				this.props.observableStore.saveEventInfo();	
			}
			else{this.setState({modalState: 3, modalError: result.data.request.errorMessage})}
		}).catch(err => {console.log(err); this.setState({modalState: 3})});
	}

	formatDate(epoch){
		var date = new Date(0);
		date.setUTCSeconds(parseFloat(epoch));
		return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2) + '-' + date.getFullYear();		
	}
	
}

export default Facturen;

