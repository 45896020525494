import React from "react";
import {Col, Row} from 'reactstrap';	
import AnimatedNumber from "animated-number-react";

class TotaalOmzet extends React.Component {	
	render() {
		return (
		<Row style={{marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0}}>
			<div className="graph-display dateselectwin side-stats">
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts side-stats-display" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div className="side-stats-icon"><i class="fa-light fa-chart-column" style={{fontSize: 20, color: 'rgba(0,0,0,0.5)', fontWeight: 400}}></i></div>
					<h2 style={{marginTop: 15, fontSize: 17, fontWeight: 900, color: '#212529',}}>€ <AnimatedNumber duration={500} value={this.state.gemStats.buy} formatValue={this.formatCurrency}/></h2>	
					<h2 style={{fontSize: 12, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Gem. opwaardering</h2>	
				</div>
				:
				<div className="campaign ct-charts side-stats-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts side-stats-display" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div className="side-stats-icon"><i class="fa-light fa-stopwatch" style={{fontSize: 20, color: 'rgba(0,0,0,0.5)', fontWeight: 400}}></i></div>
					<h2 style={{marginTop: 15, fontSize: 17, fontWeight: 900, color: '#212529',}}><AnimatedNumber duration={500} value={this.state.gemStats.ordertime} formatValue={this.formatValue}/> seconde</h2>	
					<h2 style={{fontSize: 12, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Gem. besteltijd</h2>	
				</div>
				:
				<div className="campaign ct-charts side-stats-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
			</div>
			<div className="graph-display dateselectwin side-stats">
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts side-stats-display" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div className="side-stats-icon"><i class="fa-light fa-gift" style={{fontSize: 20, color: 'rgba(0,0,0,0.5)', fontWeight: 400}}></i></div>
					<h2 style={{marginTop: 15, fontSize: 17, fontWeight: 900, color: '#212529',}}><AnimatedNumber duration={500} value={this.state.gemStats.promoTrans} formatValue={this.formatValue}/> transacties</h2>	
					<h2 style={{fontSize: 12, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Via opwaardeer links</h2>	
				</div>
				:
				<div className="campaign ct-charts side-stats-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts side-stats-display" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div className="side-stats-icon"><i class="fa-light fa-coins" style={{fontSize: 20, color: 'rgba(0,0,0,0.5)', fontWeight: 400}}></i></div>
					<h2 style={{marginTop: 15, fontSize: 17, fontWeight: 900, color: '#212529',}}><AnimatedNumber duration={500} value={this.state.gemStats.promoTokens} formatValue={this.formatValue}/> muntjes</h2>	
					<h2 style={{fontSize: 12, color: 'rgba(0,0,0,0.5)',fontWeight: 900}}>Via opwaardeer links</h2>	
				</div>
				:
				<div className="campaign ct-charts side-stats-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
			</div>
			<div className="graph-display dateselectwin side-stats">
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts side-stats-display" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div className="side-stats-icon"><i class="fa-light fa-people-arrows" style={{fontSize: 20, color: 'rgba(0,0,0,0.5)', fontWeight: 400}}></i></div>
					<h2 style={{marginTop: 15, fontSize: 17, fontWeight: 900, color: '#212529',}}><AnimatedNumber duration={500} value={this.state.gemStats.shareOrders} formatValue={this.formatValue}/> bestellingen</h2>	
					<h2 style={{fontSize: 12, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Gedeeld</h2>	
				</div>
				:
				<div className="campaign ct-charts side-stats-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts side-stats-display" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div className="side-stats-icon"><i class="fa-light fa-link" style={{fontSize: 20, color: 'rgba(0,0,0,0.5)', fontWeight: 400}}></i></div>
					<h2 style={{marginTop: 15, fontSize: 17, fontWeight: 900, color: '#212529',}}><AnimatedNumber duration={500} value={this.state.gemStats.shareTrans} formatValue={this.formatValue}/> deellinks</h2>	
					<h2 style={{fontSize: 12, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Verwerkt</h2>	
				</div>
				:
				<div className="campaign ct-charts side-stats-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
			</div>
			<div className="graph-display dateselectwin side-stats">
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts side-stats-display" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div className="side-stats-icon"><i class="fa-light fa-ticket" style={{fontSize: 20, color: 'rgba(0,0,0,0.5)', fontWeight: 400}}></i></div>
					<h2 style={{marginTop: 15, fontSize: 17, fontWeight: 900, color: '#212529',}}><AnimatedNumber duration={500} value={this.state.gemStats.tickets} formatValue={this.formatValue}/> Tickets</h2>	
					<h2 style={{fontSize: 12, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Opgehaald</h2>	
				</div>
				:
				<div className="campaign ct-charts side-stats-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
				{this.props.stats !== 'loading' ?
				<div className="campaign ct-charts side-stats-display" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					<div className="side-stats-icon"><i class="fa-light fa-basket-shopping" style={{fontSize: 20, color: 'rgba(0,0,0,0.5)', fontWeight: 400}}></i></div>
					<h2 style={{marginTop: 15, fontSize: 17, fontWeight: 900, color: '#212529',}}><AnimatedNumber duration={500} value={this.state.gemStats.ticketOrders} formatValue={this.formatValue}/> Ticket orders</h2>	
					<h2 style={{fontSize: 12, color: 'rgba(0,0,0,0.5)',fontWeight: 900,}}>Opgehaald</h2>	
				</div>
				:
				<div className="campaign ct-charts side-stats-display" style={{height: 185, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
					<div class="loading-spinner" style={{height: 50, width: 50}}></div>
				</div>
				}
			</div>
		</Row>
		);
	}	
	
	state = {gemStats: {buy: 0, return: 0, share: 0}}
	
	componentDidUpdate(prevProps) {
		if(this.props.stats !== prevProps.stats && this.props.stats !== 'loading'){			
			var totaalStats = {buytokens: 0, buytrans: 0, returntokens: 0, returntrans: 0, sharetokens: 0, sharetrans: 0, ordertime: 0, ordertrans: 0, shareorders: 0, promotokens: 0, promotrans: 0, tickets: 0, ticketOrders: 0}
			Object.values(this.props.stats).forEach(statsDate => {
				if(statsDate.tokenverkoop){
					totaalStats.buytokens = totaalStats.buytokens + (statsDate.tokenverkoop.omzet ? statsDate.tokenverkoop.omzet : 0);
					totaalStats.buytrans = totaalStats.buytrans + (statsDate.tokenverkoop.transacties ? statsDate.tokenverkoop.transacties : 0);
				}
				if(statsDate.tokenretour){
					totaalStats.returntokens = totaalStats.returntokens + (statsDate.tokenretour.retourkosten ? statsDate.tokenretour.retourkosten : 0);
					totaalStats.returntrans = totaalStats.returntrans + (statsDate.tokenretour.transacties ? statsDate.tokenretour.transacties : 0);
				}
				if(statsDate.share){
					totaalStats.sharetokens = totaalStats.sharetokens + (statsDate.share.tokens ? statsDate.share.tokens : 0);
					totaalStats.sharetrans = totaalStats.sharetrans + (statsDate.share.transacties ? statsDate.share.transacties : 0);
					totaalStats.shareorders = totaalStats.shareorders + (statsDate.share.bestellingen ? statsDate.share.bestellingen : 0);
				}
				if(statsDate.afhaalpunten){
					Object.keys(statsDate.afhaalpunten).forEach(afhaalpunt => {
						totaalStats.ordertrans = totaalStats.ordertrans + statsDate.afhaalpunten[afhaalpunt].transacties
						if(statsDate.afhaalpunten[afhaalpunt].besteltijd){totaalStats.ordertime = totaalStats.ordertime + statsDate.afhaalpunten[afhaalpunt].besteltijd}
					});		
				}
				if(statsDate.promotie){
					totaalStats.promotokens = totaalStats.promotokens + (statsDate.promotie.tokens ? statsDate.promotie.tokens : 0);
					totaalStats.promotrans = totaalStats.promotrans + (statsDate.promotie.transacties ? statsDate.promotie.transacties : 0);
				}
				if(statsDate.tickets){
					totaalStats.tickets = totaalStats.tickets + (statsDate.tickets.tickets ? statsDate.tickets.tickets : 0);
					totaalStats.ticketOrders = totaalStats.ticketOrders + (statsDate.tickets.bestellingen ? statsDate.tickets.bestellingen : 0);
				}
			});	
			this.setState({gemStats: {buy: totaalStats.buytokens / totaalStats.buytrans,return: totaalStats.returntokens / totaalStats.returntrans,shareTokens: totaalStats.sharetokens, shareTrans: totaalStats.sharetrans, shareOrders: totaalStats.shareorders, ordertime: totaalStats.ordertime / totaalStats.ordertrans, promoTokens: totaalStats.promotokens, promoTrans: totaalStats.promotrans, tickets: totaalStats.tickets, ticketOrders: totaalStats.ticketOrders}});
		}
	}
	
	formatCurrency = (value) => {return value.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
	formatValue = (value) => {return Math.round(value * 10) / 10};
}

export default TotaalOmzet;



