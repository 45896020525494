import React from "react";
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import Eventpdf from '../../components/pdf/eventpdf';
import { rtdb } from '../../components/firebase/firebase';
import { observer, inject } from 'mobx-react';

@inject('observableStore')
@observer

class exportEvent extends React.Component {

    render() {return (<div></div>)}
	
	generateEventPdf = (info, start, end, name) => {	
		var startDate = new Date(start);
		var endDate = new Date(end);
		console.log('start:' + start);
		console.log('end:' + end);
		const formatedTime = startDate.getFullYear() + '-' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '-' + ("0" + startDate.getDate()).slice(-2);
		const formatedTimeEnd = endDate.getFullYear() + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + ("0" + endDate.getDate()).slice(-2);
		var statsRef = rtdb.ref('/stats/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID)
		var onlineomzet = 0;
		var offlineomzet = 0;
		var cashomzet = 0;
		var pinomzet = 0;
		var eigengebruik = 0;
		var baromzet = 0;
		statsRef.orderByKey().startAt(formatedTime).endAt(formatedTimeEnd).once('value', (snap) => {
			for(const [key, value] of Object.entries(snap.val())){
				if(value.tokenverkoop){
					if(formatedTime === formatedTimeEnd){ 
						for(const [hourkey, hourvalue] of Object.entries(value.tokenverkoop.omzetperuur)){
							if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){onlineomzet = onlineomzet + hourvalue}
						}
					}
					else if(key === formatedTime || key === formatedTimeEnd){ 
						for(const [hourkey, hourvalue] of Object.entries(value.tokenverkoop.omzetperuur)){
							if(key === formatedTime){
								if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){onlineomzet = onlineomzet + hourvalue}
							}
							if(key === formatedTimeEnd){
								if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){onlineomzet = onlineomzet + hourvalue}
							}
						}
					}
					else{
						onlineomzet = onlineomzet + (value.tokenverkoop.omzet ? value.tokenverkoop.omzet : 0);
					}
				}
				if(value.oplaadpunten){
					Object.keys(value.oplaadpunten).forEach(oplaadpunt => {
						if(value.oplaadpunten[oplaadpunt].cash){
							if(formatedTime === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].cash)){
									if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){cashomzet = cashomzet + hourvalue}
								}
							}
							else if(key === formatedTime || key === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].cash)){
									if(key === formatedTime){
										if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){cashomzet = cashomzet + hourvalue}
									}
									if(key === formatedTimeEnd){
										if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){cashomzet = cashomzet + hourvalue}
									}
								}
							}
							else{
								cashomzet = cashomzet + (value.oplaadpunten[oplaadpunt].cash ? Object.values(value.oplaadpunten[oplaadpunt].cash).reduce((sum, num) => sum + num) : 0);
							}
						}
						if(value.oplaadpunten[oplaadpunt].pin){
							if(formatedTime === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].pin)){
									if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){pinomzet = pinomzet + hourvalue}
								}
							}
							else if(key === formatedTime || key === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].pin)){
									if(key === formatedTime){
										if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){pinomzet = pinomzet + hourvalue}
									}
									if(key === formatedTimeEnd){
										if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){pinomzet = pinomzet + hourvalue}
									}
								}
							}
							else{
								pinomzet = pinomzet + (value.oplaadpunten[oplaadpunt].pin ? Object.values(value.oplaadpunten[oplaadpunt].pin).reduce((sum, num) => sum + num) : 0);
							}
						}
						if(value.oplaadpunten[oplaadpunt].eigengebruik){
							if(formatedTime === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].eigengebruik)){
									if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){eigengebruik = eigengebruik + hourvalue}
								}
							}
							else if(key === formatedTime || key === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.oplaadpunten[oplaadpunt].eigengebruik)){
									if(key === formatedTime){
										if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){eigengebruik = eigengebruik + hourvalue}
									}
									if(key === formatedTimeEnd){
										if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){eigengebruik = eigengebruik + hourvalue}
									}
								}
							}
							else{
								eigengebruik = eigengebruik + (value.oplaadpunten[oplaadpunt].eigengebruik ? Object.values(value.oplaadpunten[oplaadpunt].eigengebruik).reduce((sum, num) => sum + num) : 0);
							}
						}
					});	
				}
				if(value.afhaalpunten){
					Object.keys(value.afhaalpunten).forEach(afhaalpunt => {
						if(value.afhaalpunten[afhaalpunt].omzetperuur){
							if(formatedTime === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.afhaalpunten[afhaalpunt].omzetperuur)){
									if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours() && parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){baromzet = baromzet + hourvalue}
								}
							}
							else if(key === formatedTime || key === formatedTimeEnd){ 
								for(const [hourkey, hourvalue] of Object.entries(value.afhaalpunten[afhaalpunt].omzetperuur)){
									if(key === formatedTime){
										if(parseFloat(hourkey.replace(':00','')) >= new Date(startDate).getHours()){baromzet = baromzet + hourvalue}
									}
									if(key === formatedTimeEnd){
										if(parseFloat(hourkey.replace(':00','')) <= (new Date(endDate).getHours() -1)){baromzet = baromzet + hourvalue}
									}
								}
							}
							else{
								for(const [hourkey, hourvalue] of Object.entries(value.afhaalpunten[afhaalpunt].omzetperuur)){baromzet = baromzet + hourvalue}
							}
						}
					});	
				}
			}
			var data={onlineomzet: onlineomzet, offlineomzet: offlineomzet, cashomzet: cashomzet, pinomzet: pinomzet, eigengebruik: eigengebruik, baromzet: baromzet}
			this.generatePDFDocument(data,info,name)
		});
	}

	generatePDFDocument = async (data,info,name) => {
		const blob = await pdf(<Eventpdf data={data} name={name} exportInfo={info} />).toBlob();
		saveAs(blob, 'Partypay_Export_' + name + '.pdf');
	};


}

export default exportEvent;



