import React from "react";
import {Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Table, ModalFooter, Button, Col, Row} from 'reactstrap';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';	
import { observer, inject } from 'mobx-react';
import { rtdb, db } from '../components/firebase/firebase';
import arrayMove from 'array-move';
import Datetime from 'react-datetime';
import TotaalOmzet from '../components/stats/totaalomzet';
import VerkoopOmzet from '../components/stats/verkoopomzet';
import OmzetPerUur from '../components/stats/omzetperuur';
import GemStats from '../components/stats/gemstats';
import AfhaalOmzet from '../components/stats/afhaalomzet';

@inject('observableStore')
@observer

class Stats extends React.Component {	
	render() {
		return (
		<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
			<Row>
				<Col lg={12}>
					<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Statistieken</h2>
				</Col>
			</Row>
			<Row style={{marginTop: 40, paddingLeft: 15, paddingRight: 15}}>
				<Col lg={2} style={{minWidth: 240, padding: 0}}>
					<div className="graph-display dateselectwin">
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Vandaag' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Vandaag' ? 6 : 0}} onClick={() => this.getStats('Vandaag')}>
							<span>Vandaag</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Gisteren' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Gisteren' ? 6 : 0}} onClick={() => this.getStats('Gisteren')}>
							<span>Gisteren</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Deze week' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Deze week' ? 6 : 0}} onClick={() => this.getStats('Deze week')}>
							<span>Deze week</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Deze maand' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Deze maand' ? 6 : 0}} onClick={() => this.getStats('Deze maand')}>
							<span>Deze maand</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Dit jaar' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Dit jaar' ? 6 : 0}} onClick={() => this.getStats('Dit jaar')}>
							<span>Dit jaar</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Vorig jaar' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Vorig jaar' ? 6 : 0}} onClick={() => this.getStats('Vorig jaar')}>
							<span>Vorig jaar</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Periode' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Periode' ? 6 : 0}} onClick={() => this.togglePeriodModal()}>
							<span>Periode</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.statsNav === 'Alles' ? '#f6f6f6' : '', borderLeftWidth: this.state.statsNav === 'Alles' ? 6 : 0}} onClick={() => this.getStats('Alles')}>
							<span>Alles</span>
						</div>
					</div>
					<GemStats stats={this.state.stats} />
				</Col>
				<Col className="rightCol">		
					<Row style={{paddingTop: 5}}>
						<Col lg={12}>
							<div style={{marginRight: 15, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
								<div style={{display: 'flex', alignItems: 'center'}}>
									<h2 className="font-light text-muted topTitle" style={{fontSize: 24, marginBottom: 0, fontWeight: 900, color: '#212529'}}>{this.state.statsNav}</h2>
									{this.state.statsNav !== 'Vandaag' && this.state.statsNav !== 'Gisteren' ?
									<h2 className="font-light text-muted topTitle" style={{marginLeft: 10, fontSize: 15, marginBottom: 0, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>{this.state.dateTitle}</h2>
									:null}
								</div>
								<div style={{display: 'flex'}}>
									<div onClick={() => this.togglelinkModal()} className="refreshBtn">
										<i class="fa-regular fa-link refreshBtnI" style={{color: '#525f7f'}}></i>
									</div>
									<div onClick={() => this.getStats(this.state.statsNav)} className="refreshBtn" style={{marginLeft: 15}}>
										<i class="fa-regular fa-sync refreshBtnI" style={{color: '#525f7f'}}></i>
									</div>
								</div>
							</div>
						</Col>
					</Row>		
					<TotaalOmzet stats={this.state.stats} />
					<VerkoopOmzet stats={this.state.stats} exportInfo={{usr: this.props.observableStore.userInfo, evt: this.props.observableStore.currEvent.naam}} />
					<OmzetPerUur stats={this.state.stats} />
					<AfhaalOmzet stats={this.state.stats} exportDate={this.state.dateTitle} exportInfo={{usr: this.props.observableStore.userInfo, evt: this.props.observableStore.currEvent.naam}}/>									
				</Col>
			</Row>
			<Modal isOpen={this.state.periodModal} toggle={() => this.togglePeriodModal()} className={this.props.className} centered >
				<ModalHeader toggle={this.togglePeriodModal}>Selecteer een periode</ModalHeader>
				<ModalBody>
					<Row>
						<Col lg={6}>
							<span style={{color: 'rgba(0,0,0,0.5)', marginTop: 20}}>van</span>
							<div className="period" style={{marginTop: 5, marginBottom: 10, width: '100%', display: 'flex', position: 'relative', alignItems: 'center'}}>
								<i class="fa fa-calendar-day" style={{position: 'absolute', right: 25, top: 13, color: '#525f7f'}}></i>
								<Datetime timeFormat={false} value={this.state.periodStart} onChange={e => this.setState({periodStart: e})} closeOnSelect={true} dateFormat="DD-MM-YYYY" locale="nl"/>
							</div>
						</Col>
						<Col lg={6}>
							<span style={{color: 'rgba(0,0,0,0.5)', marginTop: 20}}>t/m</span>
							<div className="period" style={{marginBottom: 15, marginTop: 5, width: '100%', display: 'flex', position: 'relative', alignItems: 'center'}}>												
								<i class="fa fa-calendar-day" style={{position: 'absolute', right: 25, top: 13, color: '#525f7f'}}></i>
								<Datetime timeFormat={false} value={this.state.periodEnd} onChange={e => this.setState({periodEnd: e})} closeOnSelect={true} dateFormat="DD-MM-YYYY" locale="nl"/>
							</div>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.getStats('Periode')}>
					  Bekijk statistieken
					</Button>
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={() => this.togglePeriodModal()}>
					  Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={this.state.linkModal} toggle={this.togglelinkModal} className={this.props.className} centered >
				<ModalHeader toggle={this.togglelinkModal}>Meekijk Links</ModalHeader>
				<ModalBody>
					<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Je kunt een link aanmaken waarop iemand mee kan kijken met de statistieken van het huidige evenement. Andere evenementen zijn niet inzichtbaar en je kunt de link ook op elk moment weer intrekken.</p>
					<SortableAmountList helperClass='is-moving' items={this.state.linkArray} onSortEnd={this.onLinkSortEnd} editLink={this.editLink} delLink={this.delLink} useDragHandle={true} />
					<div onClick={() => this.newLink()} style={{display: 'flex', justifyContent: 'center'}}><div className='linkSetup' style={{marginTop: 25, marginBottom: 30, fontSize: 13, width: 160}}>Link toevoegen</div></div>
					<Modal isOpen={this.state.linkAddModal} toggle={() => this.linkAddModal} className={this.props.className} centered style={{maxWidth: 400}} >
						<ModalHeader toggle={this.linkAddModal}>Link {this.state.editLinkIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label htmlFor="example-email">Notitie</Label>
								<Input type="text" id="example-email" name="name" placeholder="Bijv: naam partner bedrijf" value={this.state.newLinkNote} onChange={e => this.setState({newLinkNote: e.target.value})}/>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.saveLink()}>
								{this.state.editLinkIndex === 'new' ? 'Toevoegen' : 'Aanpassen'}
							</Button>
							<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.togglelinkAddModal}>
								Annuleren
							</Button>
						</ModalFooter>
					</Modal>
				</ModalBody>
				<ModalFooter>								
					<Button className="cancel_btn" style={{marginLeft: 10}} onClick={this.togglelinkModal}>
					  	Sluit venster
					</Button>
				</ModalFooter>
			</Modal>
		</div>
		);
	}
	
	state = {
		statsNav: '',
		dateTitle: '',
		loaded: false,
		stats: 'loading',		
		periodModal: false,
		periodStart: new Date(),
		periodEnd: new Date(),
		lastDay: new Date(),

		linkModal: false,
		linkAddModal: false,
		linkArray: [],
		newLinkNote: '',
		editLinkIndex: ''
	}
	
	componentDidMount = () => {if(this.props.observableStore.uid === 'GpJNJ5gDIZcuLkeJpVQiAIXtyU22'){this.getStats('Dit jaar')}else{this.getStats('Vandaag')}}
	
	getStats(period){
		var date = new Date();		
		var startDate = new Date();
		var endDate = new Date();
		var dateTitle = '';
		if(period === 'Vandaag'){
			startDate.setDate(date.getDate());
			endDate.setDate(date.getDate());
			dateTitle = ("0" + startDate.getDate()).slice(-2) + '-' + ("0" + (startDate.getMonth() +1)).slice(-2) + '-' + startDate.getFullYear();
		}		
		if(period === 'Gisteren'){
			startDate.setDate(date.getDate() - 1);
			endDate.setDate(date.getDate() - 1);
			dateTitle = ("0" + startDate.getDate()).slice(-2) + '-' + ("0" + (startDate.getMonth() +1)).slice(-2) + '-' + startDate.getFullYear();
		}		
		if(period === 'Deze week'){
			startDate.setDate((date.getDate() - date.getDay())+1);
			endDate.setDate((date.getDate() - date.getDay())+7);
		}		
		if(period === 'Deze maand'){
			startDate = new Date(date.getFullYear(), date.getMonth(), 1);
			endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		}		
		if(period === 'Dit jaar'){
			startDate = new Date(date.getFullYear(), 0, 1);
			endDate = new Date(date.getFullYear(), 11, 31);
		}		
		if(period === 'Vorig jaar'){
			startDate = new Date(date.getFullYear() -1, 0, 1);
			endDate = new Date(date.getFullYear() -1, 11, 31);
		}		
		if(period === 'Periode'){
			startDate = new Date(this.state.periodStart);
			endDate = new Date(this.state.periodEnd);
		}	
		if(period === 'Alles'){
			startDate = new Date(null);
			endDate = new Date(2170, 0, 1);
			dateTitle = "Alle data";
		}
		if(period === 'Deze week' || period === 'Deze maand' || period === 'Periode'){
			var startDateString = ("0" + startDate.getDate()).slice(-2) + '-' + ("0" + (startDate.getMonth() +1)).slice(-2) + '-' + startDate.getFullYear();
			var endDateString = ("0" + endDate.getDate()).slice(-2) + '-' + ("0" + (endDate.getMonth() +1)).slice(-2) + '-' + endDate.getFullYear();
			if(startDateString === endDateString){
				dateTitle = startDateString;
			}
			else{
				dateTitle = startDateString + ' t/m ' + endDateString;
			}			
		}		
		if(period === 'Dit jaar' || period === 'Vorig jaar'){
			dateTitle = startDate.getFullYear();
		}					
		this.setState({stats: 'loading', statsNav: period, dateTitle: dateTitle, periodModal: false, lastDay: endDate});
		var statsRef = rtdb.ref('/stats/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID)
		const formatedTime = new Date(startDate).getFullYear() + '-' + ("0" + (new Date(startDate).getMonth() + 1)).slice(-2) + '-' + ("0" + new Date(startDate).getDate()).slice(-2);
		const formatedTimeEnd = new Date(endDate).getFullYear() + '-' + ("0" + (new Date(endDate).getMonth() + 1)).slice(-2) + '-' + ("0" + new Date(endDate).getDate()).slice(-2);
		statsRef.orderByKey().startAt(formatedTime).endAt(formatedTimeEnd).once('value', (snap) => this.setState({loaded: true, stats: snap.val() ? snap.val() : []}));
	}
	
	togglePeriodModal = () => this.setState({'periodModal': !this.state.periodModal});		
	formatValue = (value) => {return value.toFixed(0)}	
	formatCurrency = (value) => {return value.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
	
    getPeriod = () =>{
		var date = new Date();
		if(this.state.statsNav === 'Vandaag'){	
			return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2) + '-' + date.getFullYear();
		}		
		if(this.state.statsNav === 'Gisteren'){
			date.setDate(date.getDate() - 1)
			return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() +1)).slice(-2) + '-' + date.getFullYear();
		}	
		return this.state.dateTitle;		
	}

	togglelinkModal = () => this.setState({'linkModal': !this.state.linkModal, linkArray: this.props.observableStore.currEvent.spectators ? this.props.observableStore.currEvent.spectators : []});
	togglelinkAddModal = () => this.setState({'linkAddModal': !this.state.linkAddModal});

	newLink = () => {
		this.setState({editLinkIndex: 'new', newLinkNote: ''});
		this.togglelinkAddModal();
	}
	
	editLink = (index) => {
		this.setState({editLinkIndex: index, newLinkNote: this.state.linkArray[index].note, newLink: this.state.linkArray[index].link});
		this.togglelinkAddModal();
		this.saveLinklenArray();
	}

	delLink = (ind) => {
		var linkArray = this.state.linkArray;
		linkArray.splice(ind, 1);
		this.setState({linkArray: linkArray});
		this.saveLinklenArray();
	}

	saveLink = () => {
		if(this.state.editLinkIndex === 'new'){
			var linkArray = this.state.linkArray;
			var code = db.collection("events").doc()
			linkArray.push({note: this.state.newLinkNote, link: 'https://stats.partypay.nl/?oid=' + this.props.observableStore.uid + '&eid=' + this.props.observableStore.currEvent.ID + '&code=' + code.id});
			this.setState({linkArray: linkArray});
		}
		else{this.state.linkArray[this.state.editLinkIndex].note = this.state.newLinkNote}
		this.togglelinkAddModal();
		this.saveLinklenArray();
	}

	onLinkSortEnd = ({oldIndex, newIndex}) => {
		var linkArray = this.state.linkArray;
		linkArray = arrayMove(linkArray, oldIndex, newIndex);	
		this.setState({linkArray: linkArray});	
		this.saveLinklenArray();
	}

	saveLinklenArray = () => {
		this.props.observableStore.currEvent.spectators = this.state.linkArray;
		this.props.observableStore.saveEventInfo();
	}
	
}

export default Stats;

const SortableAmountList = SortableContainer(({items, editLink, delLink}) => {
	return (
		<Table className="v-middle" style={{marginBottom: 0, borderWidth: 0}}>
			<tbody>
				{items.map((value, index) => (<SortableAmount key={index} index={index} ind={index} value={value} editLink={editLink} delLink={delLink} />))}
			</tbody>
		</Table>
	);
});

const SortableAmount = SortableElement(({value, ind, editLink, delLink}) => 
	<div className="menuoptienav" style={{paddinTop: 10, paddingBottom: 20, background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}} >
		<div style={{maxWidth: 340}}>
			<div htmlFor="staticEmail" className="col-form-label">{value.note}</div>
			<span style={{wordBreak: 'break-all', color: 'rgba(0,0,0,0.5)'}}>{value.link}</span>
		</div>
		<div style={{marginLeft: 10, display: 'flex', cursor:'pointer'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editLink(ind)}></i>
			<i class="fa fa-trash hightlightIcon2" style={{marginLeft: 12}} onClick={() => delLink(ind)}></i>
			<DragHandle />
		</div>
	</div>
);

const DragHandle = sortableHandle(() => <i class="fa-regular fa-arrows-alt hightlightIcon2" style={{marginLeft: '15%', marginRight: '10%', cursor:'pointer'}}></i>);




