import React from "react";
import arrayMove from 'array-move';
import {Row, Col, Table, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label} from 'reactstrap';
import { db } from '../components/firebase/firebase';
import { observer, inject } from 'mobx-react';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import { functions } from '../components/firebase/firebase';
import Warning from '../assets/images/pp_warning.svg';
import Datetime from 'react-datetime';
import moment from "moment";
import "moment/locale/nl";
@inject('observableStore')
@observer

class Notificaties extends React.Component {
	render() {
		return (
			<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
				<Row>
					<Col lg={12}>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Push notificaties</h2>
					</Col>
				</Row>
				<Row className="menuTopCats">			
					<Col lg={2} className="graph-display" style={{background: 'none', marginTop: 12, paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 240}}>
						<div className="settingsnav" style={{backgroundColor: this.state.notificatieCat === 'notificaties' ? '#f6f6f6' : '', borderLeftWidth: this.state.notificatieCat === 'notificaties' ? 6 : 0}} onClick={() => this.setState({notificatieCat: 'notificaties'})}>
							<span>Push notificaties</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.notificatieCat === 'mededelingen' ? '#f6f6f6' : '', borderLeftWidth: this.state.notificatieCat === 'mededelingen' ? 6 : 0}} onClick={() => this.setState({notificatieCat: 'mededelingen'})}>
							<span>Berichten centrum</span>
						</div>
					</Col>
					<Col className="graph-display graph-menu" style={{marginLeft: 20, marginTop: 12, padding: 40, paddingTop: 30}}>						
						{this.state.notificatieCat === 'notificaties' ?
						<div>
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, paddingRight: 20, fontWeight: 900, color: '#212529',}}>Alle notificaties</h2>
								<div style={{display: 'flex'}}>
									<div className="addBtnLst" style={{width: 200, background: '#d12a5e', color: '#fff'}} onClick={() => this.toggle()}>
										Verstuur een notificatie										
									</div>
								</div>
							</div>
							<div>
								{this.props.observableStore.currEvent.pushmessages && this.props.observableStore.currEvent.pushmessages.length > 0 ?
									<div style={{minHeight: 450}}>
										<SortableNotificationannouncement items={this.props.observableStore.currEvent.pushmessages} delNotification={this.delNotification} onSortEnd={this.onpushmessagesortEnd} useDragHandle={true} />
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen push notificaties verstuurd</span>
									</div>
								}
							</div>
						</div>	
						:
						<div>
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, paddingRight: 20, fontWeight: 900, color: '#212529',}}>Alle berichten</h2>
								<div style={{display: 'flex'}}>
									<div className="addBtnLst" style={{width: 200, background: '#d12a5e', color: '#fff'}} onClick={() => this.toggleMededelingenModal()}>
										Bericht toevoegen									
									</div>
								</div>
							</div>
							<div>
								{this.props.observableStore.currEvent.announcements && this.props.observableStore.currEvent.announcements.length > 0 ?
									<div style={{minHeight: 450}}>
										<Sortablemededelingen items={this.props.observableStore.currEvent.announcements} editMededeling={this.editMededeling} delMededeling={this.delMededeling} onSortEnd={this.onMededelingSortEnd} useDragHandle={true} />
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen berichten toegevoegd</span>
									</div>
								}
							</div>
						</div>	
						}				
					</Col>
				</Row>
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered >
					<ModalHeader toggle={this.toggle}>Push notificatie sturen</ModalHeader>
					<ModalBody>
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Titel</Label>
									<Input type="text" style={{borderColor: this.state.reqFields.includes('titel') ? 'red' : undefined}} id="titel" name="name" placeholder="Schrijf een titel" value={this.state.titel} onChange={e => this.setState({titel: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>	
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="bericht">Bericht</Label>
									<Input type="textarea" style={{borderColor: this.state.reqFields.includes('bericht') ? 'red' : undefined}} rows={4} id="example-email" name="name" placeholder="Schrijf een bericht..." value={this.state.bericht} onChange={e => this.setState({bericht: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Wie wil je bereiken?</Label>
									<Input type="select" style={{marginBottom: 8, paddingRight: 50, width: '100%', height: 40}} className='minimal' onChange={e => {this.setState({target: e.target.value, announcement: e.target.value === 'all' ? true : false})}}>
										<option value="all" selected={this.state.target === 'all'}>Alle bezoekers</option>
										<option value="private" selected={this.state.target === 'private'}>Specifieke bezoeker</option>
										<option value="multiple" selected={this.state.target === 'multiple'}>Alle bezoekers van meerdere events</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>
						{this.state.target === 'multiple' ?
						<Row style={{marginBottom: 25}}>
							{this.props.observableStore.eventIndex.sort((a, b) => a.tijd - b.tijd).map((evt, key) => (	
								<div toggle={false} style={{cursor: 'pointer', display: 'flex', marginTop: 7, marginLeft: 15}}>
									<div onClick={() => this.selectEvent(evt.eid)} style={{cursor: 'pointer', marginTop: 2}}>
										{this.state.selectedEvents.includes(evt.eid) ?
										<div style={{width: 18, height: 18, borderWidth: 1, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
											<i class="fa fa-check" style={{color: '#4cc15f', padding: 2, fontSize: 12, position: 'absolute'}}></i>
										</div>
										:
										<div style={{width: 18, height: 18, borderWidth: 1, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
										}
									</div>
									<div>
										{this.formatDate2(evt.tijd) ? <p style={{color: 'rgba(0,0,0,0.5)', display: 'inline', paddingRight: 8}}>{this.formatDate2(evt.tijd)}</p>:null} 
										{evt.naam}
									</div>
								</div>
							))}
						</Row>
						:null}
						{this.state.target === 'private' ?
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">E-mailadres</Label>
									<Input disabled={this.state.procesUID} type="text" style={{borderColor: this.state.reqFields.includes('mail') || this.state.wrongUID ? 'red' : undefined}} id="titel" name="name" onChange={e => this.setState({mail: e.target.value})}/>
								</FormGroup>
								{this.state.procesUID ? <div style={{position: 'absolute', top: 38, right: 30}}><div class="loading-spinner" style={{borderWidth: 2, height: 15, width: 15}}></div></div>:null}
							</Col>
						</Row>	
						:null}
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Als bezoeker op notificatie klikt</Label>
									<Input type="select" style={{marginBottom: 8, paddingRight: 50, width: '100%', height: 40}} className='minimal' onChange={e => {this.setState({action: e.target.value, copyNotf: e.target.value === 'modal' ? true : this.state.copyNotf})}}>
										<option value="" selected={this.state.action === ''}>Geen actie</option>
										<option value="pop" selected={this.state.action === 'pop'}>Pop up van melding</option>
										<option value="modal" selected={this.state.action === 'modal'}>Open berichten centrum</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>
						<Row onClick={() => this.setState({copyNotf: !this.state.copyNotf})} style={{cursor: 'pointer', marginTop: 10, marginBottom: 7, marginLeft: 1}}>
							{this.state.copyNotf ?
							<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
								<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
							</div>
							:
							<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
							}
							<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Kopiëren naar berichten centrum</p>
						</Row>
						<Row>
							<Col>
							<p style={{marginTop: 5, fontSize: 12, fontStyle: 'italic', color: 'rgba(0,0,0,0.5)'}}>De pushnotificatie zal alle bezoekers bereiken die aan de bovenstaande criteria voldoen en notificaties hebben toegestaan voor het evenement in de app.</p>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.processNotification}>
							Verstuur push notificatie
						</Button>
						<Button className="cancel_btn" onClick={this.toggle}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.mededelingenModal} toggle={this.toggleMededelingenModal} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleMededelingenModal}>Mededeling {this.state.editMededelingInd >= 0 ? 'aanpassen' : 'toevoegen'}</ModalHeader>
					<ModalBody>
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Titel</Label>
									<Input type="text" style={{borderColor: this.state.reqFields.includes('mededelingenTitel') ? 'red' : undefined}} id="titel" name="name" placeholder="Schrijf een titel" value={this.state.mededelingenTitel} onChange={e => this.setState({mededelingenTitel: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>	
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="bericht">Bericht</Label>
									<Input type="textarea" style={{borderColor: this.state.reqFields.includes('mededelingenBericht') ? 'red' : undefined}} rows={5} id="example-email" name="name" placeholder="Schrijf een bericht..." value={this.state.mededelingenBericht} onChange={e => this.setState({mededelingenBericht: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Auteur</Label>
									<Input type="text" style={{borderColor: this.state.reqFields.includes('mededelingenNaam') ? 'red' : undefined}} id="naam" name="naam" placeholder="Naam van auteur" value={this.state.mededelingenNaam} onChange={e => this.setState({mededelingenNaam: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Knop URL (optioneel)</Label>
									<Input type="text" id="naam" name="naam" placeholder="Bijv: https://google.nl" value={this.state.mededelingenBtnUrl} onChange={e => this.setState({mededelingenBtnUrl: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Knop tekst (optioneel)</Label>
									<Input type="text" id="naam" name="naam" placeholder="Bijv: Ga naar website" value={this.state.mededelingenBtnTxt} onChange={e => this.setState({mededelingenBtnTxt: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.processMededeling}>
							{this.state.editMededelingInd >= 0 ? 'Bericht aanpassen' : 'Bericht toevoegen'}
						</Button>
						<Button className="cancel_btn" onClick={this.toggleMededelingenModal}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
	
	state = {
		modal: false,
		reqFields: [],
		notificatieCat: 'notificaties',
		titel: '',
		bericht: '',
		target: 'all',
		action: '',
		start: new Date(),
		end: new Date(),
		mail: '',
		wrongUID: false,
		procesUID: false,

		mededelingenModal: false,
		editMededelingInd: -1,
		mededelingenTitel: '',
		mededelingenBericht: '',
		mededelingenNaam: '',
		mededelingenBtnUrl: '',
		mededelingenBtnTxt: '',

		copyNotf: false
	}	
	
	toggle = () => {this.setState({
		'modal': !this.state.modal, 
		reqFields: [], 
		titel: '', 
		bericht: '', 
		naam: this.props.observableStore.userInfo.bedrijfsnaam, 
		target: 'all',
		action: '',
		start: new Date(),
		end: new Date(),
		wrongUID: false,
		procesUID: false,

		copyNotf: false,

		selectedEvents: []
	})}

	processNotification = async () => {
		 if(this.reqFields()){
		 	var topic = '';
			var label = 'Alle bezoekers';
			if(this.state.target === 'all'){topic = this.props.observableStore.currEvent.ID}
			if(this.state.target === 'private'){
				try{
					this.setState({procesUID: true});
					const getUID = functions.httpsCallable('getUID');
					const user = await getUID({user: this.state.mail});
					topic = user.data.uid;
					label = this.state.mail;
				}catch(e){this.setState({procesUID: false, wrongUID: true}); return null}
			}
			if(this.state.target ==='multiple'){
				if(this.state.action === 'modal' || this.state.copyNotf){
					alert('Berichten centrum niet mogelijk bij meerdere evenementen');
				}
				else{
					this.state.selectedEvents.forEach((evt, ind) => {
						topic = topic + "'" + evt + "' in topics";
						if(ind < this.state.selectedEvents.length -1){topic = topic + " || "}
					})
				}
			}
			var announcement = {title: this.state.titel, text: this.state.bericht, target: this.state.target, label: label, action: this.state.action}
			this.props.observableStore.currEvent.pushmessages.unshift(announcement);
			if(this.state.copyNotf){
				var announcement = {naam: this.props.observableStore.userInfo.bedrijfsnaam, buttonUrl: '', buttonText: '', title: this.state.titel, text: this.state.bericht, tijd: Math.round(new Date().getTime() / 1000)}
				this.props.observableStore.currEvent.announcements.unshift(announcement);
				this.props.observableStore.saveEventInfo();	
				db.collection("events").doc(this.props.observableStore.currEvent.ID).update({announcements: this.props.observableStore.currEvent.announcements});
		 	}
			var sendMessage = functions.httpsCallable('sendMessage');
			sendMessage({title: announcement.title, msg: announcement.text, condition: this.state.target ==='multiple' ? true : false, topic: topic, action: this.state.action, target: this.state.target});
		}
		this.props.observableStore.saveEventInfo();	
		this.toggle();	
	}
	
	reqFields(){
		var reqFields = [];
		if(this.state.notificatieCat === 'notificaties' && this.state.titel === ''){reqFields.push('titel')}
		if(this.state.notificatieCat === 'notificaties' && this.state.bericht === ''){reqFields.push('bericht')}
		if(this.state.notificatieCat === 'notificaties' && this.state.target === 'private' && this.state.mail === ''){reqFields.push('mail')}
		if(this.state.notificatieCat === 'mededelingen' && this.state.mededelingenNaam === ''){reqFields.push('mededelingenNaam')}
		if(this.state.notificatieCat === 'mededelingen' && this.state.mededelingenTitel === ''){reqFields.push('mededelingenTitel')}
		if(this.state.notificatieCat === 'mededelingen' && this.state.mededelingenBericht === ''){reqFields.push('mededelingenBericht')}
		if(reqFields.length > 0){this.setState({reqFields: reqFields}); return false}
		return true;
	}

	delNotification = (index) => {
		this.props.observableStore.currEvent.pushmessages.splice(index, 1);
		this.props.observableStore.saveEventInfo();	
	}			
	
	onpushmessagesortEnd = ({oldIndex, newIndex}) => {
		this.props.observableStore.currEvent.pushmessages = arrayMove(this.props.observableStore.currEvent.pushmessages, oldIndex, newIndex);
		this.props.observableStore.saveEventInfo();	
	}	

	toggleMededelingenModal = () => {this.setState({
		mededelingenModal: !this.state.mededelingenModal, 
		editMededelingInd: -1, 
		reqFields: [], 
		mededelingenTitel: '', 
		mededelingenBericht: '', 
		mededelingenNaam: this.props.observableStore.userInfo.bedrijfsnaam, 
		mededelingenBtnUrl: '', 
		mededelingenBtnTxt: '', 
	})}
	
	editMededeling = (index) =>{
		this.setState({
			mededelingenModal: !this.state.mededelingenModal, 
			editMededelingInd: index, 
			mededelingenBericht: this.props.observableStore.currEvent.announcements[index].text, 
			mededelingenTitel: this.props.observableStore.currEvent.announcements[index].title, 
			mededelingenNaam: this.props.observableStore.currEvent.announcements[index].naam,
			mededelingenBtnUrl: this.props.observableStore.currEvent.announcements[index].buttonUrl,
			mededelingenBtnTxt: this.props.observableStore.currEvent.announcements[index].buttonText,
		});
	}

	processMededeling = async () => {
		if(this.reqFields()){
			var btnUrl = this.state.mededelingenBtnUrl === '' || this.state.mededelingenBtnUrl.includes('https://') ? this.state.mededelingenBtnUrl : ('https://' + this.state.mededelingenBtnUrl)
			if(this.state.editMededelingInd >= 0){
				this.props.observableStore.currEvent.announcements[this.state.editMededelingInd].naam = this.state.mededelingenNaam;
				this.props.observableStore.currEvent.announcements[this.state.editMededelingInd].title = this.state.mededelingenTitel;
				this.props.observableStore.currEvent.announcements[this.state.editMededelingInd].text = this.state.mededelingenBericht;
				this.props.observableStore.currEvent.announcements[this.state.editMededelingInd].buttonUrl = btnUrl;
				this.props.observableStore.currEvent.announcements[this.state.editMededelingInd].buttonText = this.state.mededelingenBtnTxt;

			}
			else{
				var announcement = {naam: this.props.observableStore.userInfo.bedrijfsnaam, buttonUrl: btnUrl, buttonText: this.state.mededelingenBtnTxt, title: this.state.mededelingenTitel, text: this.state.mededelingenBericht, tijd: Math.round(new Date().getTime() / 1000)}
				this.props.observableStore.currEvent.announcements.unshift(announcement);
			}
			this.props.observableStore.saveEventInfo();	
			db.collection("events").doc(this.props.observableStore.currEvent.ID).update({announcements: this.props.observableStore.currEvent.announcements});
			this.toggleMededelingenModal();	
		}
	}

	delMededeling = (index) => {
		var announcements = this.props.observableStore.currEvent.announcements;
		announcements.splice(index, 1);
		this.props.observableStore.currEvent.announcements = announcements;
		this.props.observableStore.saveEventInfo();	
		db.collection("events").doc(this.props.observableStore.currEvent.ID).update({announcements: announcements});
	}			
	
	onMededelingSortEnd = ({oldIndex, newIndex}) => {
		this.props.observableStore.currEvent.announcements = arrayMove(this.props.observableStore.currEvent.announcements, oldIndex, newIndex);
		this.props.observableStore.saveEventInfo();	
		db.collection("events").doc(this.props.observableStore.currEvent.ID).update({announcements: this.props.observableStore.currEvent.announcements});
	}

	selectEvent(evt){
		var selectedEvents = this.state.selectedEvents;
		if(selectedEvents.includes(evt)){selectedEvents = selectedEvents.filter(e => e !== evt)}
		else{
			if(selectedEvents.length === 5){alert('Je kunt maximaal 5 evenementen selecteren')}
			else{selectedEvents.push(evt)}
		}
		this.setState({selectedEvents: selectedEvents});
	}
	
	formatDate2(tijd){
		if(tijd && tijd === parseInt(tijd, 10)){
			var date = new Date(0);
			date.setUTCSeconds(tijd);
			return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth()+1)).slice(-2) + '-' + date.getFullYear();
		}
		return null;
	}

}

export default Notificaties;


const SortableNotificationannouncement = SortableContainer(({items, delNotification}) => {
	return (
		<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
			<thead>
				<tr>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Bericht</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Bezoeker</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Actie</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}></td>
				</tr>
			</thead>
			<tbody>{items.map((value, index) => (<SortableNotification key={`item-${value}`} index={index} ind={index} delNotification={delNotification} value={value} />))}</tbody>
		</Table>
	);
});

const SortableNotification = SortableElement(({value, ind, delNotification}) => (
	<tr style={{background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
		<td style={{padding: 20, width: '50%'}}><h6 style={{fontSize: 14, fontWeight: 500, marginBottom: 12}}>{value.title}</h6><h6 style={{fontSize: 13, fontWeight: 500, color: 'rgba(0,0,0,0.6)'}}>{value.text.length > 250 ? `${value.text.substring(0, 250)}...` : value.text}</h6></td>	
		<td style={{padding: 20, width: '20%'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.label}</h6></td>
		<td style={{padding: 20, width: '20%'}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.action === '' ? 'Geen actie' : ''}{value.action === 'pop' ? 'Popup melding' : ''}{value.action === 'modal' ? 'Open mededelingen' : ''}</h6></td>
		<td style={{padding: 20, fontSize: 18, minWidth: 100, padding: 0, textAlign: 'right'}}>
			<i class="fa fa-trash hightlightIcon2" onClick={() => delNotification(ind)} style={{cursor:'pointer', marginLeft: '15%',}}></i>
			<DragHandle />
		</td>
	</tr>
));

const Sortablemededelingen = SortableContainer(({items, editMededeling, delMededeling}) => {
	return (
		<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
			<thead>
				<tr>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Bericht</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}></td>
				</tr>
			</thead>
			<tbody>{items.map((value, index) => (<Sortablemededeling key={`item-${value}`} index={index} ind={index} editMededeling={editMededeling} delMededeling={delMededeling} value={value} />))}</tbody>
		</Table>
	);
});

const Sortablemededeling = SortableElement(({value, ind, editMededeling, delMededeling}) => (
	<tr style={{background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
		<td style={{padding: 20, width: '90%'}}><h6 style={{fontSize: 14, fontWeight: 500, marginBottom: 12}}>{value.title}</h6><h6 style={{fontSize: 13, fontWeight: 500, color: 'rgba(0,0,0,0.6)'}}>{value.text.length > 250 ? `${value.text.substring(0, 250)}...` : value.text}</h6></td>	
		<td style={{padding: 20, fontSize: 18, minWidth: 100, padding: 0, textAlign: 'right'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editMededeling(ind)} style={{cursor:'pointer'}}></i>
			<i class="fa fa-trash hightlightIcon2" onClick={() => delMededeling(ind)} style={{cursor:'pointer', marginLeft: '15%',}}></i>
			<DragHandle />
		</td>
	</tr>
));

const DragHandle = sortableHandle(() => <i class="fa-regular fa-arrows-alt hightlightIcon2" style={{marginLeft: '15%', marginRight: '10%', cursor:'pointer'}}></i>);