import React from "react";
import {Col, Row, Table, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu} from 'reactstrap';	
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { Line } from 'react-chartjs-2';
import AfhaalPdf from '../../components/pdf/afhaalpdf';
import Warning from '../../assets/images/pp_warning.svg';

class itemOmzet extends React.Component {
	render() {
		return (
			<div>
			<Row style={{paddingTop: 35}}>
				<Col lg={12}>
					{this.props.stats !== 'loading' ?
					<div className="graph-display">
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<h2 className="font-light text-muted" style={{marginTop: 10, fontWeight: 900, paddingRight: 20, fontSize: 20, color: '#212529',}}>Afhaalpunten</h2>	
							<div style={{display: 'flex'}}>
								<UncontrolledDropdown nav inNavbar onToggle={()=>{return;}}>
									<DropdownToggle nav className="styledDropdown" style={{width: 235}}>Selecteer afhaalpunten</DropdownToggle>
									<DropdownMenu className="dropdownCheckWindow">
										{Object.keys(this.state.afhaalStats).filter(afhaalpunt => afhaalpunt !== 'totaal').map(afhaalpunt => (											
											<div className="dropdownCheckitem">
												 <input type="checkbox" className="dropdownCheckbox" checked={this.state.selectedAfhaalpunten.includes(afhaalpunt)} onClick={()=>this.toggleAfhaalpunt(afhaalpunt)}/>
												{this.state.afhaalStats[afhaalpunt].naam}
											</div>											
										))}
									</DropdownMenu>
								</UncontrolledDropdown>		
								<div onClick={() => generatePDFDocument(this.state.afhaalStats,this.props.exportDate,this.props.exportInfo,this.state.selectedAfhaalpunten)} className="refreshBtn" style={{height: 35, fontSize: 11, paddingTop: 2}}>PDF</div>
							</div>							
						</div>	
						<div className="statsTable">
							<Table className="v-middle" responsive style={{marginTop: 15}}>
								<thead>
									<tr>
										<td className="stats-td" style={{width: '30%', fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Afhaalpunt</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Bestellingen</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Muntjes</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Eigen gebruik</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Omzet excl.</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>BTW</td>
										<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Omzet incl.</td>
									</tr>
								</thead>
								<tbody>
								{Object.keys(this.state.afhaalStats).filter(afhaalpunt => this.state.selectedAfhaalpunten.includes(afhaalpunt)).map((afhaalpunt, index) => (										
									<tr style={{background: index%2 !== 0 ? null : 'rgba(0,0,0,0.03)'}}>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.afhaalStats[afhaalpunt].naam}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.afhaalStats[afhaalpunt].transacties}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.afhaalStats[afhaalpunt].tokens}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>€ {this.state.afhaalStats[afhaalpunt].promotie.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>€ {(this.state.afhaalStats[afhaalpunt].omzet - this.state.afhaalStats[afhaalpunt].btw).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>€ {this.state.afhaalStats[afhaalpunt].btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										<td className="stats-td stats-td-omzet"><h6 style={{background: '#43A047', fontWeight: 500, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ {this.state.afhaalStats[afhaalpunt].omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
									</tr>	
								))}
								{Object.keys(this.state.afhaalStats).filter(afhaalpunt => afhaalpunt !== 'totaal').length > 0 ? 
								<tr>
									<td className="stats-td"><h6 style={{paddingTop: 8, fontSize: 14, fontWeight: 900}}>Totaal</h6></td>
									<td className="stats-td"><h6 style={{paddingTop: 8, fontSize: 14, fontWeight: 900}}>{Object.keys(this.state.afhaalStats).filter(afhaalpunt => this.state.selectedAfhaalpunten.includes(afhaalpunt)).reduce((a, b) => a + this.state.afhaalStats[b].transacties, 0)}</h6></td>
									<td className="stats-td"><h6 style={{paddingTop: 8, fontSize: 14, fontWeight: 900}}>{Object.keys(this.state.afhaalStats).filter(afhaalpunt => this.state.selectedAfhaalpunten.includes(afhaalpunt)).reduce((a, b) => a + this.state.afhaalStats[b].tokens, 0)}</h6></td>
									<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 900}}>€ {(Object.keys(this.state.afhaalStats).filter(afhaalpunt => this.state.selectedAfhaalpunten.includes(afhaalpunt)).reduce((a, b) => a + this.state.afhaalStats[b].promotie, 0)).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
									<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 900}}>€ {(Object.keys(this.state.afhaalStats).filter(afhaalpunt => this.state.selectedAfhaalpunten.includes(afhaalpunt)).reduce((a, b) => a + (this.state.afhaalStats[b].omzet - this.state.afhaalStats[b].btw), 0)).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
									<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 900}}>€ {(Object.keys(this.state.afhaalStats).filter(afhaalpunt => this.state.selectedAfhaalpunten.includes(afhaalpunt)).reduce((a, b) => a + this.state.afhaalStats[b].btw, 0)).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
									<td className="stats-td stats-td-omzet"><h6 style={{paddingTop: 8, fontSize: 14, fontWeight: 900, textAlign: 'center'}}>€ {(Object.keys(this.state.afhaalStats).filter(afhaalpunt => this.state.selectedAfhaalpunten.includes(afhaalpunt)).reduce((a, b) => a + this.state.afhaalStats[b].omzet, 0)).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
								</tr>
								:
								<div style={{height: 300, width: '100%'}}>
									<div className="emptytable">
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen items gescanned</span>
									</div>
								</div>
								}
								</tbody>
							</Table>
						</div>
					</div>
					:
					<div className="campaign ct-charts graph-display" style={{height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<div class="loading-spinner" style={{height: 50, width: 50}}></div>
					</div>
					}
				</Col>
			</Row>
			<Row style={{paddingTop: 35}}>
				<Col lg={12}>
					{this.props.stats !== 'loading' && this.state.showChart ?
					<div className="graph-display">
						<div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
							<h2 className="font-light text-muted" style={{marginTop: 5, marginBottom: 30, paddingRight: 20, fontWeight: 900, fontSize: 20, color: '#212529',}}>Afhaalpunten per uur</h2>									
						</div>
						<div style={{height: 340}}>
							<div style={{position: 'absolute', width: '100%', paddingRight: 76}}>
								<div className="chart-wrapper" style={{width: '100%', margin: '0 auto', height: 340}}>
									<Line data={this.state.lineData} options={{
										elements: { line: { borderJoinStyle: 'round' } }, 
										tooltips: { backgroundColor: '#d12a5e', yPadding: 10, xPadding: 10, displayColors: false, bodyFontSize: 13, borderColor: '#fff', borderWidth: 2, titleFontSize: 14, mode: 'x-axis', callbacks: { label: (t, d) => { return '  ' + d.datasets[t.datasetIndex].label + ': ' + t.yLabel.toLocaleString("nl-NL",{style:"currency", currency:"EUR"})}}}, 
										maintainAspectRatio: false, 
										layout: { padding: { left: 0, right: 0, top: 15, bottom: 5 } }, 
										legend: { mode: 'index', intersect: false, display: true, labels: { fontFamily: "Kentledge" } }, 
										hover: { mode: 'index', intersect: false },
										scales: { 
										yAxes: [{ display: true, stacked: false, gridLines: { drawBorder: false, display: true, color: 'rgba(0,0,0,0.15)', borderDash: [4, 6] }, ticks: {padding: 20, display: true, maxTicksLimit: 5, fontFamily: "Kentledge", callback: (value, index, values) => { return value}}}], 
										xAxes: [{ gridLines: { display: false, color: 'rgba(0,0,0,0.1)', borderDash: [8, 4] }, ticks: { padding: 15, fontFamily: "Kentledge", fontSize: 11, fontStyle: "bold", callback: function(value) {return value + '-' + value.substring(0, 3) + '59'}},
										onClick: function(evt, element) {console.log('clicked')}
									}]}}}/>	
								</div>
							</div>
						</div>
					</div>
					:
					<div className="campaign ct-charts graph-display" style={{height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<div class="loading-spinner" style={{height: 50, width: 50}}></div>
					</div>
					}
				</Col>
			</Row>
			<Row style={{paddingTop: 35}}>
				<Col lg={12}>
					{this.props.stats !== 'loading' ?
					<div className="graph-display" style={{paddingBottom: 45, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
						<div style={{display: 'flex', justifyContent: 'space-between'}}>
							<h2 className="font-light text-muted" style={{marginTop: 10, fontSize: 20, paddingRight: 20, fontWeight: 900, color: '#212529',}}>Verkoop</h2>								
							<div style={{display: 'flex'}}>
								<Input type="text" id="example-email" style={{marginRight: 15, width: 180}} name="name" placeholder="Zoek op items" value={this.state.searchInput} onChange={e => this.setState({searchInput: e.target.value.toLowerCase()})}/>
								<Input type="select" className='minimal' style={{width: 225}} onChange={e => this.setState({currAfhaalpunt: e.target.value})}>
									{Object.keys(this.state.afhaalStats).map(afhaalpunt => (	
										<option value={afhaalpunt} selected={this.state.currAfhaalpunt === afhaalpunt}>{afhaalpunt === 'totaal' ? 'Alle afhaalpunten' : this.state.afhaalStats[afhaalpunt].naam}</option>
									))}
								</Input>
							</div>
						</div>
						<div className="statsTable">
							<Table className="v-middle" responsive style={{marginTop: 15, marginBottom: 0}}>								
							<thead>
								<tr>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)'}}>Item</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)', cursor: 'pointer'}} onClick={() => this.setState({sortBy: 'aantal'})}>
										Stuks
										{this.state.sortBy === 'aantal' ? <i class="fa-regular fa-chevron-down" style={{position: 'absolute', color: '#525f7f', fontWeight: 400, marginLeft: 6, marginTop: 3}}></i>:null}
									</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)', cursor: 'pointer'}} onClick={() => this.setState({sortBy: 'tokens'})}>
										Muntjes
										{this.state.sortBy === 'tokens' ? <i class="fa-regular fa-chevron-down" style={{position: 'absolute', color: '#525f7f', fontWeight: 400, marginLeft: 6, marginTop: 3}}></i>:null}
									</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)', cursor: 'pointer'}} onClick={() => this.setState({sortBy: 'promotie'})}>
										Eigen gebruik
									{this.state.sortBy === 'promotie' ? <i class="fa-regular fa-chevron-down" style={{position: 'absolute', color: '#525f7f', fontWeight: 400, marginLeft: 6, marginTop: 3}}></i>:null}
									</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)', cursor: 'pointer'}} onClick={() => this.setState({sortBy: 'omzet'})}>
										Omzet excl.
									</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)', cursor: 'pointer'}} onClick={() => this.setState({sortBy: 'btw'})}>
										BTW
									{this.state.sortBy === 'btw' ? <i class="fa-regular fa-chevron-down" style={{position: 'absolute', color: '#525f7f', fontWeight: 400, marginLeft: 6, marginTop: 3}}></i>:null}
									</td>
									<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.5)', cursor: 'pointer'}} onClick={() => this.setState({sortBy: 'omzet'})}>
										Omzet incl.
									{this.state.sortBy === 'omzet' ? <i class="fa-regular fa-chevron-down" style={{position: 'absolute', color: '#525f7f', fontWeight: 400, marginLeft: 6, marginTop: 3}}></i>:null}
									</td>
								</tr>
							</thead>
							<tbody>
								{Object.entries(this.state.afhaalStats[this.state.currAfhaalpunt].items).filter(item => item[1].aantal !== 0).sort((a, b) => { return b[1][this.state.sortBy] - a[1][this.state.sortBy]}).map((item, index) => {									
									if(this.state.searchInput.length < 2 || this.state.searchInput.length >= 2 && item[0].toLowerCase().includes(this.state.searchInput) || this.state.searchInput.length >= 2 && item[0].toLowerCase().includes(this.state.searchInput)){
									return <tr style={{background: index%2 !== 0 ? null : 'rgba(0,0,0,0.03)'}}>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{item[0]}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{item[1].aantal}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>{item[1].tokens}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>€ {item[1].promotie.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>€ {(item[1].omzet - item[1].btw).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										<td className="stats-td"><h6 style={{fontSize: 14, fontWeight: 500}}>€ {item[1].btw.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2})}</h6></td>
										<td className="stats-td stats-td-omzet"><h6 style={{background: '#43A047', fontWeight: 500, textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 7}}>€ {(item[1].omzet.toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}))}</h6></td>
									</tr>	
									}
								})}
							</tbody>
							</Table>
						</div>
						{Object.entries(this.state.afhaalStats[this.state.currAfhaalpunt].items).length === 0 ? 
							<div style={{height: 300, width: '100%'}}>
								<div className="emptytable">
									<img src={Warning} style={{width: 50, marginBottom: 15}} />
									<span>Er zijn nog geen items gescanned</span>
								</div>
							</div>
						:null}
					</div>
					:
					<div className="campaign ct-charts graph-display" style={{height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<div class="loading-spinner" style={{height: 50, width: 50}}></div>
					</div>
					}
				</Col>
			</Row>
			</div>
		);
	}
	
	state = {showChart: false, lineData: {}, afhaalStats: {'totaal': {items: {}}}, currAfhaalpunt: 'totaal', sortBy: 'omzet', selectedAfhaalpunten: [], searchInput: ''}
	
	componentDidUpdate(prevProps) {
		if(this.props.stats !== prevProps.stats && this.props.stats !== 'loading'){		
			var afhaalStats = {'totaal': {items: {}}};		
			var selectedAfhaalpunten = [];
			var graphStats = {}
			var dataSets = [];
			var labels = [];
			Object.values(this.props.stats).forEach(statsDate => {
				if(statsDate.afhaalpunten){
					Object.keys(statsDate.afhaalpunten).forEach(afhaalpunt => {	
						if(!selectedAfhaalpunten.includes(afhaalpunt)){selectedAfhaalpunten.push(afhaalpunt)}
						if(!(afhaalpunt in afhaalStats)){afhaalStats[afhaalpunt] = {items: {}, transacties: 0, tokens: 0, omzet: 0, btw: 0, promotie: 0, promotieBTW: 0}}						
						afhaalStats[afhaalpunt].naam = statsDate.afhaalpunten[afhaalpunt].naam;
						afhaalStats[afhaalpunt].transacties = afhaalStats[afhaalpunt].transacties + (statsDate.afhaalpunten[afhaalpunt].transacties ? statsDate.afhaalpunten[afhaalpunt].transacties : 0);	
						afhaalStats['totaal'].transacties = afhaalStats['totaal'].transacties + (statsDate.afhaalpunten[afhaalpunt].transacties ? statsDate.afhaalpunten[afhaalpunt].transacties : 0);	
						Object.keys(statsDate.afhaalpunten[afhaalpunt].items).forEach(item => {
							var itemPromo = (statsDate.afhaalpunten[afhaalpunt].items[item].promotie ? statsDate.afhaalpunten[afhaalpunt].items[item].promotie : 0);
							var itemOmzet = statsDate.afhaalpunten[afhaalpunt].items[item].omzet - itemPromo;
							var itemBTW = itemOmzet / (100 + (100* statsDate.afhaalpunten[afhaalpunt].items[item].btw)) * (100* statsDate.afhaalpunten[afhaalpunt].items[item].btw);
							afhaalStats[afhaalpunt].items[item] = {
								aantal: (item in afhaalStats[afhaalpunt].items ? afhaalStats[afhaalpunt].items[item].aantal : 0) + statsDate.afhaalpunten[afhaalpunt].items[item].aantal,
								tokens: (item in afhaalStats[afhaalpunt].items ? afhaalStats[afhaalpunt].items[item].tokens : 0) + statsDate.afhaalpunten[afhaalpunt].items[item].tokens,
								omzet: (item in afhaalStats[afhaalpunt].items ? afhaalStats[afhaalpunt].items[item].omzet : 0) + itemOmzet,
								btw: (item in afhaalStats[afhaalpunt].items ? afhaalStats[afhaalpunt].items[item].btw : 0) + itemBTW,
								promotie: (item in afhaalStats[afhaalpunt].items ? afhaalStats[afhaalpunt].items[item].promotie : 0) + itemPromo
							};
							afhaalStats['totaal'].items[item] = {
								aantal: (item in afhaalStats['totaal'].items ? afhaalStats['totaal'].items[item].aantal : 0) + statsDate.afhaalpunten[afhaalpunt].items[item].aantal,
								tokens: (item in afhaalStats['totaal'].items ? afhaalStats['totaal'].items[item].tokens : 0) + statsDate.afhaalpunten[afhaalpunt].items[item].tokens,
								omzet: (item in afhaalStats['totaal'].items ? afhaalStats['totaal'].items[item].omzet : 0) + itemOmzet,
								btw: (item in afhaalStats['totaal'].items ? afhaalStats['totaal'].items[item].btw : 0) + itemBTW,
								promotie: (item in afhaalStats['totaal'].items ? afhaalStats['totaal'].items[item].promotie : 0) + itemPromo							
							};
							afhaalStats[afhaalpunt].omzet = afhaalStats[afhaalpunt].omzet + itemOmzet;		
							afhaalStats[afhaalpunt].tokens = afhaalStats[afhaalpunt].tokens + statsDate.afhaalpunten[afhaalpunt].items[item].tokens;								
							afhaalStats[afhaalpunt].btw = afhaalStats[afhaalpunt].btw + itemBTW;	
							afhaalStats[afhaalpunt].promotie = afhaalStats[afhaalpunt].promotie + itemPromo;						
						});
						var afhaalName = statsDate.afhaalpunten[afhaalpunt].naam;
						if('omzetperuur' in statsDate.afhaalpunten[afhaalpunt]){
							Object.keys(statsDate.afhaalpunten[afhaalpunt].omzetperuur).forEach(uur => {
								if(!(afhaalName in graphStats)){graphStats[afhaalName] = {}}
								graphStats[afhaalName][uur] = (uur in graphStats[afhaalName] ? graphStats[afhaalName][uur] : 0) + statsDate.afhaalpunten[afhaalpunt].omzetperuur[uur];
								labels.push(uur);
							});
						}
					});
				}
			});
			labels = [...new Set(labels)];
			if(labels.length > 0 && labels.sort().slice(-1)[0] !== '23:00'){labels.push((parseFloat((labels.sort().slice(-1)[0]).split(":")[0]) + 1) + ':00')}	
			Object.keys(graphStats).forEach(afhaalpunt => {
				var randcolor = this.getColor();
				var data = [];
				labels.sort().forEach(uur =>{if(graphStats[afhaalpunt][uur]){data.push(graphStats[afhaalpunt][uur])}else{data.push(0)}})
				dataSets.push({...this.defaultDataset, ...{label: afhaalpunt, backgroundColor: randcolor.substring(0, randcolor.length - 1) + ',.1)', borderColor: randcolor, data: data, pointBackgroundColor: randcolor}});
			});
			this.setState({'afhaalStats': afhaalStats, 'selectedAfhaalpunten': selectedAfhaalpunten, currAfhaalpunt: 'totaal', lineData: {datasets: dataSets, labels: labels}},()=>this.setState({showChart: true}));
		}
	}
	
	toggleAfhaalpunt(ahp){
		var afhaalpunten = this.state.selectedAfhaalpunten;
		if(afhaalpunten.includes(ahp)){afhaalpunten = afhaalpunten.filter(afhaalpunt => afhaalpunt !== ahp)}
		else{afhaalpunten.push(ahp)}
		this.setState({'selectedAfhaalpunten': afhaalpunten});
	}
	
	toggleUitdraaiModal = () => this.setState({'uitdraaiModal': !this.state.uitdraaiModal, 'uitdraaiAfhaalId': 'totaal'});

	getColor = () => {
		var r = Math.floor(Math.random() * 255);
		var g = Math.floor(Math.random() * 255);
		var b = Math.floor(Math.random() * 255);
		return "rgb(" + r + "," + g + "," + b + ")";
	}

	defaultDataset = {
		borderWidth: 2, 
		pointRadius: 6, 
		pointHoverRadius: 10, 
		pointHitRadius: 10, 
		pointBorderColor: '#f6f6f6', 
		pointBorderWidth: 5
	};
	
}

const generatePDFDocument = async (afhaalStats,date,info,afhaalpunten) => {
	var afhaalpuntnaam = '';
	if(afhaalpunten.length === Object.keys(afhaalStats).length -1){afhaalpuntnaam = 'Alle afhaalpunten_'}
	else{afhaalpunten.forEach(afp => {afhaalpuntnaam = afhaalpuntnaam + afhaalStats[afp].naam + '_'})}
	const blob = await pdf(<AfhaalPdf afhaalStats={afhaalStats} exportDate={date} exportInfo={info} afhaalpunten={afhaalpunten} />).toBlob();
	saveAs(blob, 'Uitdraai_' + afhaalpuntnaam + info.evt + ".pdf");
};

export default itemOmzet;



