import React from "react";
import classnames from 'classnames';
import arrayMove from 'array-move';
import {Row, Col, Table, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import { db, functions, fieldvalue, rtdb } from '../components/firebase/firebase';
import Warning from '../assets/images/pp_warning.svg';

@inject('observableStore')
@observer
class Barren extends React.Component {
	render() {
		return (
			<div style={{paddingLeft: 10, paddingRight: 10, paddingBottom: 100}}>
				<Row>
					<Col lg={12}>
						<h2 className="font-light text-muted topTitle" style={{fontSize: 28, fontWeight: 900, color: '#212529'}}>Afhaalpunten</h2>
					</Col>
				</Row>
				<Row className="menuTopCats">
					<Col lg={2} className="graph-display" style={{background: 'none', marginTop: 12, paddingTop: 0, paddingRight: 0, paddingLeft: 0, minWidth: 240}}>
						<div className="settingsnav" style={{backgroundColor: this.state.verkoopCat === 'afhaalpunten' ? '#f6f6f6' : '', borderLeftWidth: this.state.verkoopCat === 'afhaalpunten' ? 6 : 0}} onClick={() => this.setState({verkoopCat: 'afhaalpunten'})}>
							<span>Afhaalpunten</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.verkoopCat === 'oplaadpunten' ? '#f6f6f6' : '', borderLeftWidth: this.state.verkoopCat === 'oplaadpunten' ? 6 : 0}} onClick={() => this.setState({verkoopCat: 'oplaadpunten'})}>
							<span>Oplaadpunten</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.verkoopCat === 'scanners' ? '#f6f6f6' : '', borderLeftWidth: this.state.verkoopCat === 'scanners' ? 6 : 0}} onClick={() => this.getScanners()}>
							<span>Scanners</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.verkoopCat === 'cateraars' ? '#f6f6f6' : '', borderLeftWidth: this.state.verkoopCat === 'cateraars' ? 6 : 0}} onClick={() => this.getCateraars()}>
							<span>Cateraars</span>
						</div>
						<div className="settingsnav" style={{backgroundColor: this.state.verkoopCat === 'terminals' ? '#f6f6f6' : '', borderLeftWidth: this.state.verkoopCat === 'terminals' ? 6 : 0}} onClick={() => this.getTerminalData()}>
							<span>Terminals</span>
						</div>
					</Col>
					<Col className="graph-display graph-menu" style={{marginLeft: 20, marginTop: 12, padding: 40, paddingTop: 30}}>	
						{this.state.verkoopCat === 'afhaalpunten' ?	
						<div>
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 20, fontWeight: 900, color: '#212529',}}>Afhaalpunten</h2>
								<div style={{display: 'flex'}}>
									<div className="addBtnLst" style={{width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.newItem()}>
										Toevoegen										
									</div>
								</div>
							</div>
							<div>
								{this.props.observableStore.currEvent.barren && this.props.observableStore.currEvent.barren.length > 0 ?
									<div style={{minHeight: 450}}>
										<SortableBarList items={this.props.observableStore.currEvent.barren} editBar={this.editBar} delBar={this.delBar} onSortEnd={this.onBarSortEnd} useDragHandle={true} />
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen afhaalpunten toegevoegd</span>
									</div>
								}
							</div>
						</div>
						:null}
						
						{this.state.verkoopCat === 'oplaadpunten' ?	
						<div>
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 20, fontWeight: 900, color: '#212529',}}>Oplaadpunten</h2>
								<div style={{display: 'flex'}}>
									<div className="addBtnLst" style={{width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.newOplaadpunt()}>
										Toevoegen										
									</div>
								</div>
							</div>
							<div>
								{this.props.observableStore.currEvent.oplaadpunten && this.props.observableStore.currEvent.oplaadpunten.length > 0 ?
									<div style={{minHeight: 450}}>
										<SortableOplaadList items={this.props.observableStore.currEvent.oplaadpunten} editOplaadpunt={this.editOplaadpunt} delOplaadpunt={this.delOplaadpunt} onSortEnd={this.onOplaadSortEnd} useDragHandle={true} />
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen oplaadpunten toegevoegd</span>
									</div>
								}
							</div>
						</div>
						:null}
				
						{this.state.verkoopCat === 'scanners' ?	
						<div>
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 20, fontWeight: 900, color: '#212529',}}>Scanners</h2>
								<div style={{display: 'flex'}}>
									<div className="addBtnLst" style={{marginLeft: 15, width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.toggleScannerModal()}>
										Toevoegen										
									</div>
								</div>
							</div>
							<div>
								{this.state.loaded && this.state.scanners && this.state.scanners.length > 0 ?
									<div style={{minHeight: 450}}>
										<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
											<thead>
												<tr>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Mail</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Naam</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Functie</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Afhaal/oplaadpunt</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Aanslaan</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}></td>
												</tr>
											</thead>
											<tbody>
												{this.state.scanners.map((scan,ind) => (	
												<tr style={{background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{scan.mail}</h6></td>
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{scan.naam}</h6></td>
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{scan.function === 'afhaal' ? 'bestelling scannen' : 'saldo opwaarderen'}</h6></td>
													{scan.barid === 'all' ?
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{scan.function === 'afhaal' ? 'Alle afhaalpunten' : 'Alle oplaadpunten'}</h6></td>
													:
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{scan.function === 'afhaal' ? this.props.observableStore.currEvent.barren.find(bar => bar.id === scan.barid).barname : this.props.observableStore.currEvent.oplaadpunten.find(oplaadpunt => oplaadpunt.id === scan.barid).name}</h6></td>
													}
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{scan.manual ? 'ja' : 'nee'}</h6></td>
													<td style={{fontSize: 18, minWidth: 120, padding: 0, textAlign: 'right'}}>
														<i class="fa fa-edit hightlightIcon2" onClick={() => this.editScanner(ind)} style={{cursor:'pointer'}}></i>
														<i class="fa fa-trash hightlightIcon2" onClick={() => this.delScanner(scan.uid)} style={{cursor:'pointer', marginLeft: '15%',}}></i>
													</td>
												</tr>
												))}
											</tbody>
										</Table>									
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen scanners toegevoegd</span>
									</div>
								}
							</div>
						</div>
						:null}
						
						{this.state.verkoopCat === 'cateraars' ?	
						<div>
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 20, fontWeight: 900, color: '#212529',}}>Cateraars</h2>
								<div style={{display: 'flex'}}>
									<div className="addBtnLst" style={{marginLeft: 15, width: 150, background: '#d12a5e', color: '#fff'}} onClick={() => this.toggleCateraarModal()}>
										Toevoegen										
									</div>
								</div>
							</div>
							<div>
								{this.state.loaded && this.state.cateraars && this.state.cateraars.length > 0 ?
									<div style={{minHeight: 450}}>
										<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
											<thead>
												<tr>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Mail</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Omschrijving</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Afhaalpunt</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Menu Categorie</td>
													<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}></td>
												</tr>
											</thead>
											<tbody>
												{this.state.cateraars.map((cat,ind) => (	
												<tr style={{background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{cat.mail}</h6></td>
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{cat.info}</h6></td>
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{cat.afhaalpunt}</h6></td>
													<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.props.observableStore.currEvent.menus[this.props.observableStore.currMenu] && this.props.observableStore.currEvent.menus[this.props.observableStore.currMenu].menu.find(men => men.cateraar === cat.uid) ? this.props.observableStore.currEvent.menus[this.props.observableStore.currMenu].menu.find(men => men.cateraar === cat.uid).name : ''}</h6></td>
													<td style={{fontSize: 18, minWidth: 120, padding: 0, textAlign: 'right'}}>
														<i class="fa fa-edit hightlightIcon2" onClick={() => this.editCateraar(ind)} style={{cursor:'pointer'}}></i>
														<i class="fa fa-trash hightlightIcon2" onClick={() => this.delCateraar(cat.uid)} style={{cursor:'pointer', marginLeft: '15%',}}></i>
													</td>
												</tr>
												))}
											</tbody>
										</Table>									
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn nog geen cateraars toegevoegd</span>
									</div>
								}
							</div>
						</div>
						:null}

						{this.state.verkoopCat === 'terminals' ?	
						<div>
							<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 5}}>
								<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 20, fontWeight: 900, color: '#212529',}}>Terminals</h2>
								<div onClick={() => this.getTerminalData()} className="refreshBtn" style={{marginLeft: 15}}>
									<i class="fa-regular fa-sync refreshBtnI" style={{color: '#525f7f'}}></i>
								</div>
							</div>
							{this.state.terminalState === 1 ?
							<div>
								{this.state.terminals.afhaalpunten && Object.keys(this.state.terminals.afhaalpunten).length > 0 && Object.keys(this.state.terminals.afhaalpunten).find(afhaalpunt => this.state.terminals.afhaalpunten[afhaalpunt].lastupdate > (Math.round(new Date().getTime() / 1000) - 86400)) ?
									<div style={{minHeight: 450}}>
										{Object.keys(this.state.terminals.afhaalpunten).map(afhaalpunt => (
										<div>
											{this.state.terminals.afhaalpunten[afhaalpunt].lastupdate > (Math.round(new Date().getTime() / 1000) - 86400) ?
											<div>
												<h2 className="font-light text-muted" style={{marginTop: 10, marginRight: 20, fontSize: 16, fontWeight: 900, color: '#d12a5e', marginTop: 30}}>{this.state.terminals.afhaalpunten[afhaalpunt].naam}</h2>
												<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
													<thead>
														<tr>
															<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Serial nummer</td>
															<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Laatst online</td>
															<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Bestellingen verwerkt</td>
															<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Bestellingen geannuleerd</td>
															<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Status</td>
														</tr>
													</thead>
													<tbody>
														{Object.keys(this.state.terminals.afhaalpunten[afhaalpunt].terminals).map((terminal,ind) => (	
														<tr style={{background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
															<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{terminal}</h6></td>
															<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.formatDate(this.state.terminals.afhaalpunten[afhaalpunt].terminals[terminal].lastupdate)}</h6></td>
															<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.terminals.afhaalpunten[afhaalpunt].terminals[terminal].orders}</h6></td>
															<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{this.state.terminals.afhaalpunten[afhaalpunt].terminals[terminal].returns}</h6></td>
															<td style={{fontSize: 18, padding: 0}}>
																{this.state.terminals.afhaalpunten[afhaalpunt].terminals[terminal].lastupdate > (Math.round(new Date().getTime() / 1000) - 660) ?
																	<h6 style={{width: 125, fontWeight: 500, background: '#43A047', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>Online</h6>
																:
																	<h6 style={{width: 125, fontWeight: 500, background: '#e53935', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>Offline</h6>
																}
															</td>
														</tr>
														))}
													</tbody>
												</Table>
											</div>
											:null}
										</div>
										))}						
									</div>
								:
									<div className="emptyContainer" style={{borderWidth: 0, minHeight: 450, flexDirection: 'column', justifyContent: 'center'}}>
										<img src={Warning} style={{width: 50, marginBottom: 15}} />
										<span>Er zijn de afgelopen 24 uur geen terminals online geweest</span>
									</div>
								}
							</div>
							:
							<div style={{width: '100%', minHeight: 450, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40}}>
								<div class="loading-spinner" style={{width: 35, height: 35, borderWidth: 4}}></div>
								<p style={{marginTop: 20}}>Terminal data ophalen</p>
							</div>
							}
						</div>
						:null}
						

					</Col>
				</Row>
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} centered >
					<ModalHeader toggle={this.toggle}>Afhaalpunt {this.state.editBarInd >= 0 ? 'aanpassen' : 'toevoegen'}</ModalHeader>
					<ModalBody>
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Naam</Label>
									<Input type="text" style={{borderColor: this.state.reqFields.includes('newBarName') ? 'red' : undefined}} id="example-email" name="name" placeholder="Bijv: Mainstage links" value={this.state.newBarName} onChange={e => this.setState({newBarName: e.target.value})}/>
								</FormGroup>	
							</Col>

						</Row>
						<Row>
							<Col sm={8}>
								<FormGroup>
									<Label htmlFor="example-email">Aantal scanners</Label>
									<Input type="number" id="example-email" name="price" placeholder="Bijv: 2" value={this.state.newBarScan} onChange={e => this.setState({newBarScan: e.target.value})}/>
								</FormGroup>
							</Col>
							<Col sm={4}>
								<Row onClick={() => this.setState({newBarState: !this.state.newBarState})} style={{cursor: 'pointer', marginTop:35, marginLeft: 1}}>
									{this.state.newBarState ?
									<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
										<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
									</div>
									:
									<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
									}
									<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Actief</p>
								</Row>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.addBar}>
							{this.state.editBarInd >= 0 ? 'Aanpassen' : 'Toevoegen'}
						</Button>
						<Button className="cancel_btn" onClick={this.toggle}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.oplaadModal} toggle={this.toggleOplaad} className={this.props.className} centered >
					<ModalHeader toggle={this.toggleOplaad}>Oplaadpunt {this.state.editOplaadInd >= 0 ? 'aanpassen' : 'toevoegen'}</ModalHeader>
					<ModalBody>
						<FormGroup>
							<Label htmlFor="example-email">Naam</Label>
							<Input type="text" style={{borderColor: this.state.reqFields.includes('newOplaadName') ? 'red' : undefined}} id="example-email" name="name" placeholder="Bijv: Ingang links" value={this.state.newOplaadName} onChange={e => this.setState({newOplaadName: e.target.value})}/>
						</FormGroup>						
						<Row>
							<Col sm={7}>
							<FormGroup>
								<Label htmlFor="example-email">Aantal scanners</Label>
								<Input type="number" id="example-email" name="price" placeholder="Bijv: 2" value={this.state.newOplaadScan} onChange={e => this.setState({newOplaadScan: e.target.value})}/>
							</FormGroup>
							</Col>
							<Col sm={5}>
								<Row onClick={() => this.setState({newOplaadState: !this.state.newOplaadState})} style={{cursor: 'pointer', marginTop:35, marginLeft: 1}}>
									{this.state.newOplaadState ?
									<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
										<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
									</div>
									:
									<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
									}
									<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Actief</p>
								</Row>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={this.addOplaadpunt}>
							{this.state.editOplaadInd >= 0 ? 'Aanpassen' : 'Toevoegen'}
						</Button>
						<Button className="cancel_btn" onClick={this.toggleOplaad}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.cateraarModal} toggle={this.toggleCateraarModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleCateraarModal}>Account toevoegen</ModalHeader>								
				{this.state.createStatus === 'wait' ?
					<ModalBody>
						<Row>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Email adress:</Label>
									<Input type="text" style={{height: 40, borderColor: this.state.reqFields.includes('cateraarMail') ? 'red' : undefined}} id="example-email" value={this.state.cateraarMail} name="name" onChange={e => this.setState({cateraarMail: e.target.value})}/>
								</FormGroup>
							</Col>
						</Row>
						<Row  style={{paddingTop:5}}>
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Afhaalpunt:</Label>
									<Input type="select" style={{marginBottom: 8, width: '100%', height: 40, borderColor: this.state.reqFields.includes('cateraarBarId') ? 'red' : undefined}} className='minimal' onChange={e => {this.setState({cateraarBarId: e.target.value})}}>
										<option value={-1}>Selecteer afhaalpunt</option>
										{this.props.observableStore.currEvent.barren ? this.props.observableStore.currEvent.barren.map(bar => (	
											<option value={bar.id} selected={this.state.cateraarBarId === bar.id}>{bar.barname.charAt(0).toUpperCase() + bar.barname.slice(1)}</option>
										)):null}
									</Input>
								</FormGroup>								
							</Col>
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Menu Categorie:</Label>
									<Input type="select" style={{marginBottom: 8, width: '100%', height: 40, borderColor: this.state.reqFields.includes('cateraarBarId') ? 'red' : undefined}} className='minimal' onChange={e => this.setState({menuCat: e.target.value})}>
										<option value={-1}>Selecteer categorie</option>
										{this.props.observableStore.currEvent.menus && this.props.observableStore.currEvent.menus[this.props.observableStore.currMenu] ? this.props.observableStore.currEvent.menus[this.props.observableStore.currMenu].menu.map((cat, index) => (	
											<option value={index} selected={cat.cateraar === this.state.cateraarUID && this.state.cateraarUID !== ""}>{cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}</option>
										)):null}
									</Input>
								</FormGroup>	
							</Col>
						</Row>
						<Row style={{paddingTop:10}}>
							<Col sm={12}>
								<FormGroup>
									<Label htmlFor="example-email">Omschrijving</Label>
									<Input type="textarea" style={{borderColor: this.state.reqFields.includes('cateraarInfo') ? 'red' : undefined}} id="example-email" name="name" placeholder="Bijv: Burger truck, foodhall stage" value={this.state.cateraarInfo} onChange={e => this.setState({cateraarInfo: e.target.value})}/>
								</FormGroup>	
							</Col>
						</Row>
					</ModalBody>
					:null}								
					{this.state.createStatus === 'process' ?
						<ModalBody>
							<div style={{padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<div class="loading-spinner" style={{height: 50, width: 50}}></div>
								<div style={{fontSize: 14, width: '100%', textAlign: 'center', marginTop: 40}}>Cateraar rechten toevoegen...</div>
							</div>
						</ModalBody>
					:null}	
					{this.state.createStatus === 'processAcc' ?
						<ModalBody>
							<div style={{padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<div class="loading-spinner" style={{height: 50, width: 50}}></div>
								<div style={{fontSize: 14, width: '100%', textAlign: 'center', marginTop: 40}}>Account aanmaken...</div>
							</div>
						</ModalBody>
					:null}								
					{this.state.createStatus === 'succes' ?
						<ModalBody>
							<div style={{fontSize: 16, width: '100%', textAlign: 'center', padding: 20}}>Account aangemaakt. Het wachtwoord is: {this.state.newPass}</div>
						</ModalBody>
					:null}								
					{this.state.createStatus === 'failed' ?
						<ModalBody>
							<div style={{fontSize: 16, width: '100%', textAlign: 'center', padding: 20}}>Er is iets mis gegaan met het aanpassen/aanmaken van het account</div>
						</ModalBody>
					:null}
					{this.state.createStatus === 'noacc' ?
						<ModalBody>
							<div style={{fontSize: 16, width: '100%', textAlign: 'center', padding: 20}}>Er bestaat nog geen account met dit e-mailadres. Account aanmaken?</div>
						</ModalBody>
					:null}	
					<ModalFooter>
						{this.state.createStatus === 'wait' ?
							<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.addCateraar()}>
							   toevoegen
							</Button>
						:null}
						{this.state.createStatus === 'noacc' ?
							<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.createAcc(this.state.cateraarMail, 'cateraar')}>
							   Account aanmaken
							</Button>
						:null}
						<Button className="cancel_btn" onClick={this.toggleCateraarModal}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
				<Modal isOpen={this.state.scannerModal} toggle={this.toggleScannerModal} className={this.props.className} centered >
				<ModalHeader toggle={this.toggleScannerModal}>Scanner toevoegen</ModalHeader>								
				{this.state.createStatus === 'wait' ?
					<ModalBody>
						<Row>
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Naam</Label>
									<Input type="text" style={{height: 40, borderColor: this.state.reqFields.includes('scannerNaam') ? 'red' : undefined}} id="example-email" name="name" placeholder="Bijv: naam groep of persoon" value={this.state.scannerNaam} onChange={e => this.setState({scannerNaam: e.target.value})}/>
								</FormGroup>	
							</Col>
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Email adress:</Label>
									<Input type="text" style={{height: 40, borderColor: this.state.reqFields.includes('scannerMail') ? 'red' : undefined}} id="example-email" value={this.state.scannerMail} name="name" onChange={e => this.setState({scannerMail: e.target.value})}/>
								</FormGroup>
							</Col>	
						</Row>
						<Row>
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Functie</Label>
									<Input type="select" style={{marginBottom: 8, width: '100%', height: 40}} className='minimal' onChange={e => {this.setState({scannerFunc: e.target.value, scannerBarId: 'all'})}}>
										<option value="afhaal" selected={this.state.scannerFunc === 'afhaal'}>Bestelling scannen</option>
										<option value="oplaad" selected={this.state.scannerFunc === 'oplaad'}>Saldo opwaarderen</option>
									</Input>
								</FormGroup>
							</Col>
							{this.state.scannerFunc === 'afhaal' ?
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Afhaalpunt</Label>
									<Input type="select" style={{marginBottom: 8, width: '100%', height: 40}} className='minimal' onChange={e => {this.setState({scannerBarId: e.target.value})}}>
										<option value={'all'} selected={this.state.scannerBarId === 'all'}>Alle afhaalpunten</option>
										{this.props.observableStore.currEvent.barren ? this.props.observableStore.currEvent.barren.map(bar => (	
											<option value={bar.id} selected={this.state.scannerBarId === bar.id}>{bar.barname.charAt(0).toUpperCase() + bar.barname.slice(1)}</option>
										)):null}
									</Input>
								</FormGroup>								
							</Col>
							:null}
							{this.state.scannerFunc === 'oplaad' ?
							<Col sm={6}>
								<FormGroup>
									<Label htmlFor="example-email">Oplaadpunt</Label>
									<Input type="select" style={{marginBottom: 8, width: '100%', height: 40, borderColor: this.state.reqFields.includes('scannerBarId') ? 'red' : undefined}} className='minimal' onChange={e => {this.setState({scannerBarId: e.target.value})}}>
										<option value={'all'} selected={this.state.scannerBarId === 'all'}>Alle oplaadpunten</option>
										{this.props.observableStore.currEvent.oplaadpunten ? this.props.observableStore.currEvent.oplaadpunten.map(oplaadpunt => (	
											<option value={oplaadpunt.id} selected={this.state.scannerBarId === oplaadpunt.id}>{oplaadpunt.name.charAt(0).toUpperCase() + oplaadpunt.name.slice(1)}</option>
										)):null}
									</Input>
								</FormGroup>								
							</Col>
							:null}
							{this.state.scannerFunc === 'afhaal' ?
							<Col sm={12}>
								<Row onClick={() => this.setState({scannerManual: !this.state.scannerManual})} style={{cursor: 'pointer', marginTop: 15, marginLeft: 1}}>
									{this.state.scannerManual ?
									<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: '#4cc15f', borderRadius: 4, marginRight: 10}}>
										<i class="fa fa-check" style={{color: '#4cc15f', paddingLeft: 1, marginTop: 2, position: 'absolute'}}></i>
									</div>
									:
									<div style={{width: 20, height: 20, borderWidth: 2, borderStyle: 'solid', borderColor: 'rgba(0,0,0,0.2)', borderRadius: 4, marginRight: 10}}></div>
									}
									<p style={{fontSize: 13, marginTop: 2, color: '#4a4a4a'}}>Optie om eigen gebruik aan te slaan</p>
								</Row>
							</Col>
							:null}
						</Row>
					</ModalBody>
					:null}								
					{this.state.createStatus === 'process' ?
						<ModalBody>
							<div style={{padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<div class="loading-spinner" style={{height: 50, width: 50}}></div>
								<div style={{fontSize: 14, width: '100%', textAlign: 'center', marginTop: 40}}>Scanner rechten toevoegen...</div>
							</div>
						</ModalBody>
					:null}	
					{this.state.createStatus === 'processAcc' ?
						<ModalBody>
							<div style={{padding: 25, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<div class="loading-spinner" style={{height: 50, width: 50}}></div>
								<div style={{fontSize: 14, width: '100%', textAlign: 'center', marginTop: 40}}>Account aanmaken...</div>
							</div>
						</ModalBody>
					:null}								
					{this.state.createStatus === 'succes' ?
						<ModalBody>
							<div style={{fontSize: 16, width: '100%', textAlign: 'center', padding: 20}}>Account aangemaakt. Het wachtwoord is: {this.state.newPass}</div>
						</ModalBody>
					:null}								
					{this.state.createStatus === 'failed' ?
						<ModalBody>
							<div style={{fontSize: 16, width: '100%', textAlign: 'center', padding: 20}}>Er is iets mis gegaan met het aanpassen/aanmaken van het account</div>
						</ModalBody>
					:null}
					{this.state.createStatus === 'noacc' ?
						<ModalBody>
							<div style={{fontSize: 16, width: '100%', textAlign: 'center', padding: 20}}>Er bestaat nog geen account met dit e-mailadres. Account aanmaken?</div>
						</ModalBody>
					:null}	
					<ModalFooter>
						{this.state.createStatus === 'wait' ?
							<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.addScanner()}>
							   toevoegen
							</Button>
						:null}
						{this.state.createStatus === 'noacc' ?
							<Button style={{backgroundColor: '#d12a5e', border: 0}} onClick={() => this.createAcc(this.state.scannerMail, 'scanner')}>
							   Account aanmaken
							</Button>
						:null}
						<Button className="cancel_btn" onClick={this.toggleScannerModal}>
						  Annuleren
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
	
state = {
		verkoopCat: 'afhaalpunten',
		modal: false,
		oplaadModal: false,
		
		newBarId: '',
		newBarName: '',
		newBarScan: 1,
		newBarState: true,
		editBarInd: -1,
		
		newOplaadName: '',
		newOplaadId: '',
		newOplaadScan: 1,
		newOplaadState: true,
		editOplaadInd: -1,
		
		cateraars: [],
		scanners: [],
		terminals: {},
		terminalState: 0,
		cateraarModal: false,
		scannerModal: false,
		cateraarMail: '',
		scannerMail: '',
		scannerBarId: 'all',
		scannerManual: false,
		menuCat: '',
		cateraarInfo: '',
		scannerNaam: '',
		newPass: '',	
		scannerFunc: 'afhaal',
		createStatus: 'wait',
		cateraarBarId: '',
		cateraarUID: '',
		
		reqFields: []
	}	

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({activeTab: tab});
		}
	}
	
	toggle = () => {this.setState({'modal': !this.state.modal, reqFields: []})}
	toggleOplaad = () => {this.setState({'oplaadModal': !this.state.oplaadModal, reqFields: []})}
	toggleCateraarModal = () => {this.setState({cateraarModal: !this.state.cateraarModal, reqFields: [], createStatus: 'wait', cateraarMail: '', menuCat: '', cateraarInfo: '', cateraarBarId: -1, cateraarUID: ''})}
	toggleScannerModal = () => {this.setState({scannerModal: !this.state.scannerModal, reqFields: [], createStatus: 'wait', scannerMail: '', scannerBarId: 'all', scannerManual: false, scannerNaam: '', scannerFunc: 'afhaal'})}
	
	getCateraars(){
		this.setState({verkoopCat: 'cateraars', loaded: false})
		var cateraars = [];
		db.collection("cateraars").orderBy(`events.${this.props.observableStore.currEvent.ID}`).get().then(querySnapshot => {
			querySnapshot.forEach(doc => {
				var cateraarBar = this.props.observableStore.currEvent.barren.find(bar => bar.id === doc.data().events[this.props.observableStore.currEvent.ID].apid);
				cateraars.push({uid: doc.id, mail: doc.data().mail, afhaalid: doc.data().events[this.props.observableStore.currEvent.ID].apid, afhaalpunt: cateraarBar ? cateraarBar.barname : '', info: doc.data().events[this.props.observableStore.currEvent.ID].info})
			});
			this.setState({cateraars: cateraars, loaded: true});
		});	
	}

	getScanners(){
		this.setState({verkoopCat: 'scanners', loaded: false})
		var scanners = [];
		db.collection("events").doc(this.props.observableStore.currEvent.ID).collection('users').where(`scanner.active`, "==", true).get().then(querySnapshot => {
			querySnapshot.forEach(doc => {
				scanners.push({uid: doc.id, mail: doc.data().scanner.mail, naam: doc.data().scanner.name, function: doc.data().scanner.function, barid: doc.data().scanner.barid, manual: doc.data().scanner.manual})
			});
			this.setState({scanners: scanners, loaded: true});
		});	
	}

	newItem(){
		this.setState({editBarInd: -1, newBarName: '', newBarId: Math.random().toString(36).substr(2, 10), newBarScan: 1, newBarState: true});
		this.toggle();	
	}
	
	newOplaadpunt(){
		this.setState({editOplaadInd: -1, newOplaadName: '', newOplaadId: Math.random().toString(36).substr(2, 10), newOplaadScan: 1, newOplaadState: true});
		this.toggleOplaad();	
	}
	
	editBar = (index) =>{
		this.setState({editBarInd: index, newBarName: this.props.observableStore.currEvent.barren[index].barname, newBarScan: this.props.observableStore.currEvent.barren[index].barscan, newBarState: this.props.observableStore.currEvent.barren[index].status});
		this.toggle();
	}
	
	editOplaadpunt = (index) =>{
		this.setState({editOplaadInd: index, newOplaadName: this.props.observableStore.currEvent.oplaadpunten[index].name, newOplaadScan: this.props.observableStore.currEvent.oplaadpunten[index].scan, newOplaadState: this.props.observableStore.currEvent.oplaadpunten[index].status});
		this.toggleOplaad();
	}

	editScanner = (index) =>{
		this.setState({scannerModal: !this.state.scannerModal, reqFields: [], createStatus: 'wait', scannerMail: this.state.scanners[index].mail, scannerBarId: this.state.scanners[index].barid, scannerManual: this.state.scanners[index].manual, scannerNaam: this.state.scanners[index].naam, scannerFunc: this.state.scanners[index].function});
	}

	editCateraar = (index) =>{
		this.setState({cateraarUID: this.state.cateraars[index].uid, cateraarModal: !this.state.cateraarModal, reqFields: [], createStatus: 'wait', cateraarMail: this.state.cateraars[index].mail, cateraarInfo: this.state.cateraars[index].info, cateraarBarId: this.state.cateraars[index].afhaalid});
	}
	
	addBar = () => {	
		if(this.reqFields('afhaal')){	
			var barArr = this.props.observableStore.currEvent.barren;
			if(this.state.editBarInd >= 0){
				barArr[this.state.editBarInd].barname = this.state.newBarName;
				barArr[this.state.editBarInd].barscan = this.state.newBarScan;				
				barArr[this.state.editBarInd].status = this.state.newBarState;
				this.toggle();
			}
			else{
				barArr.push({id: this.state.newBarId, barname: this.state.newBarName, barscan: this.state.newBarScan, status: this.state.newBarState});
				this.setState({newBarName: '', newBarId: '', newBarScan: 1, newBarState: true});
				this.toggle();	
			}
			this.props.observableStore.saveEventInfo('afhaal');	
		}
	}
	
	addOplaadpunt = () => {	
		if(this.reqFields('oplaad')){
			var barArr = this.props.observableStore.currEvent.oplaadpunten;
			if(this.state.editOplaadInd >= 0){
				barArr[this.state.editOplaadInd].name = this.state.newOplaadName;
				barArr[this.state.editOplaadInd].scan = this.state.newOplaadScan;
				barArr[this.state.editOplaadInd].status = this.state.newOplaadState;
				this.toggleOplaad();
			}
			else{
				barArr.push({id: this.state.newOplaadId, name: this.state.newOplaadName, scan: this.state.newOplaadScan, status: this.state.newOplaadState});
				this.setState({newOplaadName: '', newOplaadId: '', newOplaadScan: 1, newOplaadState: true});
				this.toggleOplaad();	
			}
			this.props.observableStore.saveEventInfo('oplaad');	
		}
	}

	addCateraar = () => {
		if(this.reqFields('cateraar')){
			var getUID = functions.httpsCallable('getUID');
			this.setState({'createStatus': 'process'});
			getUID({user: this.state.cateraarMail}).then(result => {
				var eventObj = {oid: this.props.observableStore.currEvent.oid, ename: this.props.observableStore.currEvent.naam, apid: this.state.cateraarBarId, info: this.state.cateraarInfo, menus: [{label: 'Standaard', items: []}]};
				var cateraarObj = {mail: this.state.cateraarMail, events: {[`${this.props.observableStore.currEvent.ID}`]:eventObj}, currEvent: this.props.observableStore.currEvent.ID};
				db.collection("cateraars").doc(result.data.uid).set(cateraarObj, {merge: true}).then(() => {
					rtdb.ref('/cateraars/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID).update({[result.data.uid]:this.state.cateraarBarId}).then(()=>{
						if(this.state.menuCat !== ''){
							var prevCatMen = this.props.observableStore.currEvent.menus[this.props.observableStore.currMenu].menu.findIndex(men => men.cateraar === result.data.uid);
							if(prevCatMen !== -1){this.props.observableStore.currEvent.menus[this.props.observableStore.currMenu].menu[prevCatMen].cateraar = ''}
							this.props.observableStore.currEvent.menus[this.props.observableStore.currMenu].menu[this.state.menuCat].cateraar = result.data.uid;
							this.props.observableStore.saveEventInfo('menu');
						}
						this.getCateraars();
						if(this.state.newPass === ''){this.toggleCateraarModal()}
						else{this.setState({'createStatus': 'succes'})}
					}).catch(() => this.setState({'createStatus': 'failed'}));
				}).catch(() => this.setState({'createStatus': 'failed'}));
			}).catch(() => this.setState({'createStatus': 'noacc'}));
		}
	}

	addScanner = () => {
		if(this.reqFields('scanner')){
			var getUID = functions.httpsCallable('getUID');
			this.setState({'createStatus': 'process'});
			getUID({user: this.state.scannerMail}).then(result => {
				var scannerObj = {active: true, mail: this.state.scannerMail, barid: this.state.scannerBarId, manual: this.state.scannerManual, name: this.state.scannerNaam, function: this.state.scannerFunc};
				db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("users").doc(result.data.uid).set({scanner: scannerObj},{merge: true}).then(() => {
					rtdb.ref('/scanners/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID).update({[result.data.uid]:true}).then(()=>{
						this.getScanners();
						if(this.state.newPass === ''){this.toggleScannerModal()}
						else{this.setState({'createStatus': 'succes'})}
					}).catch(() => this.setState({'createStatus': 'failed'}));
				}).catch(() => this.setState({'createStatus': 'failed'}));
			}).catch(() => this.setState({'createStatus': 'noacc'}));
		}
	}

	createAcc = (mail, mode) => {
		this.setState({'createStatus': 'processAcc'});
		var createAcc = functions.httpsCallable('createAcc');
		createAcc({eid: this.props.observableStore.currEvent.ID, mail: mail, role: ''}).then(result => {
			if(result.data.text === 'succes'){
				this.setState({newPass: result.data.pass});
				return mode === 'scanner' ? this.addScanner() : this.addCateraar();
			}
			else{
				this.setState({'createStatus': 'failed', 'createStatus': 'failed'});
			}
		}).catch(err => this.setState({'createStatus': 'failed'}));
	}

	delBar = (index) => {
		this.props.observableStore.currEvent.barren.splice(index, 1);
		this.props.observableStore.saveEventInfo('afhaal');	
	}	
	
	delOplaadpunt = (index) => {
		this.props.observableStore.currEvent.oplaadpunten.splice(index, 1);
		this.props.observableStore.saveEventInfo('oplaad');	
	}	
	
	delCateraar = (uid) => {
		db.collection("cateraars").doc(uid).set({events: {[`${this.props.observableStore.currEvent.ID}`]:fieldvalue.delete()}},{merge: true}).then(()=>this.getCateraars()).then(()=>{
			rtdb.ref('/cateraars/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID).update({[uid]:null})
		});
	}

	
	delScanner = (uid) => {
		db.collection("events").doc(this.props.observableStore.currEvent.ID).collection("users").doc(uid).update({scanner: {active: false}}).then(()=>this.getScanners()).then(()=>{
			rtdb.ref('/scanners/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID).update({[uid]:null})
		});
	}

	onBarSortEnd = ({oldIndex, newIndex}) => {
		this.props.observableStore.currEvent.barren = arrayMove(this.props.observableStore.currEvent.barren, oldIndex, newIndex);
		this.props.observableStore.saveEventInfo('afhaal');	
	}
	
	onOplaadSortEnd = ({oldIndex, newIndex}) => {
		this.props.observableStore.currEvent.oplaadpunten = arrayMove(this.props.observableStore.currEvent.oplaadpunten, oldIndex, newIndex);
		this.props.observableStore.saveEventInfo('oplaad');	
	}
	
	reqFields(type){
		var reqFields = [];
		if(type === 'afhaal'){
			if(!this.state.newBarName || this.state.newBarName === ''){reqFields.push('newBarName')}
		}
		if(type === 'oplaad'){
			if(!this.state.newOplaadName || this.state.newOplaadName === ''){reqFields.push('newOplaadName')}
		}
		if(type === 'cateraar'){
			if(!this.re.test(String(this.state.cateraarMail).toLowerCase())){reqFields.push('cateraarMail')}
			if(!this.state.cateraarInfo || this.state.cateraarInfo === ''){reqFields.push('cateraarInfo')}
			if(!this.state.cateraarBarId || this.state.cateraarBarId === -1){reqFields.push('cateraarBarId')}
		}
		if(type === 'scanner'){
			if(this.state.scannerMail === ''){reqFields.push('scannerMail')}
			if(this.state.scannerNaam === ''){reqFields.push('scannerNaam')}
		}
		if(reqFields.length > 0){
			this.setState({reqFields: reqFields});
			return false;
		}
		return true;
	}

	getTerminalData(){
		this.setState({verkoopCat: 'terminals', terminalState: 0},()=>{
			var statsRef = rtdb.ref('/terminals/' + this.props.observableStore.uid + '/' + this.props.observableStore.currEvent.ID);
			statsRef.once('value', (snap) => this.setState({terminals: snap.val() ? snap.val() : []})).then(()=>this.setState({terminalState: 1}));
		})
	}

	formatDate(epoch){
		var date = new Date(0);
		date.setUTCSeconds(parseFloat(epoch));
		return (date.getHours()<10?'0':'') + date.getHours() + ':' + (date.getMinutes()<10?'0':'') + date.getMinutes();		
	}
	
	re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
}

export default Barren;


const SortableBarList = SortableContainer(({items, editBar, delBar}) => {
	return (
		<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
			<thead>
				<tr>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Naam</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Scanners</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Status</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}></td>
				</tr>
			</thead>
			<tbody>
				{items.map((value, index) => (<SortableBar key={`item-${value}`} index={index} ind={index} editBar={editBar} delBar={delBar} value={value} />))}
			</tbody>
		</Table>
	);
});

const SortableBar = SortableElement(({value, ind, editBar, delBar}) => 
	<tr style={{opacity: value.hidden ? 0.5 : 1, background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
		<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.barname}</h6></td>
		<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.barscan} Scanners</h6></td>
		<td style={{fontSize: 18, width: '20%', padding: 0, textAlign: 'right'}}>
		{value.status ?
			<h6 style={{width: 125, fontWeight: 500, background: '#43A047', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>Actief</h6>
		:
			<h6 style={{width: 125, fontWeight: 500, background: '#e53935', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>Inactief</h6>
		}
		</td>
		<td style={{fontSize: 18, minWidth: 120, padding: 0, textAlign: 'right'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editBar(ind)} style={{cursor:'pointer'}}></i>
			<i class="fa fa-trash hightlightIcon2" onClick={() => delBar(ind)} style={{cursor:'pointer', marginLeft: '15%',}}></i>
			<DragHandle />
		</td>
	</tr>
);

const SortableOplaadList = SortableContainer(({items, editOplaadpunt, delOplaadpunt}) => {
	return (
		<Table className="v-middle" responsive style={{marginBottom: 0, borderWidth: 0}}>
			<thead>
				<tr>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Naam</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Scanners</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}>Status</td>
					<td className="stats-td" style={{fontSize: 14, fontWeight: 900, color: 'rgba(0,0,0,0.4)'}}></td>
				</tr>
			</thead>
			<tbody>
				{items.map((value, index) => (<SortableOplaadpunt key={`item-${value}`} index={index} ind={index} editOplaadpunt={editOplaadpunt} delOplaadpunt={delOplaadpunt} value={value} />))}
			</tbody>
		</Table>
	);
});

const SortableOplaadpunt = SortableElement(({value, ind, editOplaadpunt, delOplaadpunt}) => 
	<tr style={{opacity: value.hidden ? 0.5 : 1, background: ind%2 === 0 ? null : 'rgba(0,0,0,0.03)'}}>
		<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.name}</h6></td>
		<td style={{padding: 20}}><h6 style={{fontSize: 14, fontWeight: 500}}>{value.scan} Scanners</h6></td>
		<td style={{fontSize: 18, width: '20%', padding: 0, textAlign: 'right'}}>
		{value.status ?
			<h6 style={{width: 125, fontWeight: 500, background: '#43A047', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>Actief</h6>
		:
			<h6 style={{width: 125, fontWeight: 500, background: '#e53935', textAlign: 'center', borderRadius: 5, color: '#fff', padding: 6, paddingTop: 8}}>Inactief</h6>
		}
		</td>		
		<td style={{fontSize: 18, minWidth: 120, padding: 0, textAlign: 'right'}}>
			<i class="fa fa-edit hightlightIcon2" onClick={() => editOplaadpunt(ind)} style={{cursor:'pointer'}}></i>
			<i class="fa fa-trash hightlightIcon2" onClick={() => delOplaadpunt(ind)} style={{cursor:'pointer', marginLeft: '15%',}}></i>
			<DragHandle />
		</td>
	</tr>
);

const DragHandle = sortableHandle(() => <i class="fa-regular fa-arrows-alt hightlightIcon2" style={{marginLeft: '15%', marginRight: '10%', cursor:'pointer'}}></i>);